import React, { useEffect, useState } from "react";
import {
  Container,
  CircularProgress,
  Typography,
  Box,
  Button,
  useMediaQuery,
  Select,
  MenuItem,
  ToggleButton,
  ToggleButtonGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

import StatCalendar from "../components/StatCalendar";
import ActivityGrid from "../components/ActivityGrid";
import { formatTime, formatTime2, convertDistance, convertElevation, numberWithCommas } from "../utils/stringUtils";
import useComponentSize from "../utils/useComponentSize.js";
import FontPicker from "../components/FontPicker.js";
import AllColorPicker from "../components/AllColorPicker.js";

import stat_month_activities from "../services/stat_month_activities.json";
import { PolylineGroupToSVG } from "../components/Polyline";

import { FaMountain } from "react-icons/fa";
import { FaClock } from "react-icons/fa";
import { FaRuler } from "react-icons/fa";

function StatMonthActivities() {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const [componentRef, size] = useComponentSize(); // 컴포넌트 크기 확인
  const [width, setWidth] = useState(500); // 초기 너비 설정

  const [activities, setActivities] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [selectedMonth, setSelectedMonth] = useState(dayjs());
  const [activityStates, setActivityStates] = useState({});
  const [unitSystem, setUnitSystem] = useState("metric");
  const [isCondensed, setIsCondensed] = useState(false); // 축약 상태 관리
  const [totalStats, setTotalStats] = useState({
    totalDistance: 0,
    totalElevationGain: 0,
    totalMovingTime: 0,
    summaryPolylineBySport: {},
  });
  const [selectedSportTypes, setSelectedSportTypes] = useState([]); // 선택된 sportType들
  const [allSportTypes, setAllSportTypes] = useState([]); // 해당 월의 모든 sportType들
  const [fontFamily, setFontFamily] = useState([]);
  const [fontColor, setFontColor] = useState('#FFFFFF');
  const [bgColor, setBgColor] = useState('#000000');
  const [applyFontColorToPolyline, setApplyFontColorToPolyline] = useState(false);

  const [viewMode, setViewMode] = useState("polyline"); // 뷰 모드 상태 추가

  // 크기 변경 시 차트 너비 업데이트 + 로딩이 끝났을 때 강제로 사이즈 계산
  useEffect(() => {
    if (!loading && size.width) {
      console.log('size.width', size.width)
      setWidth(size.width);
    }
  }, [loading, size]);

  useEffect(() => {
    loadActivities();
  }, [selectedMonth]);

  useEffect(() => {
    const { totalDistance, totalElevationGain, totalMovingTime, summaryPolylineBySport } = getSelectedTotalStats();
    setTotalStats({
      totalDistance,
      totalElevationGain,
      totalMovingTime,
      summaryPolylineBySport,
    });
  }, [activityStates, activities]);

  const loadActivities = async () => {
    setLoading(true);
    try {
      const filteredActivities = stat_month_activities.response.filter(
        (activity) => {
          const activityDate = dayjs(activity.startDateLocal);
          return (
            activityDate.year() === selectedMonth.year() &&
            activityDate.month() === selectedMonth.month()
          );
        }
      );

      const availableSportTypes = [
        ...new Set(filteredActivities.map((activity) => activity.sportType)),
      ];
      setAllSportTypes(availableSportTypes);
      setSelectedSportTypes(availableSportTypes);

      const initialActivityStates = {};
      filteredActivities.forEach((activity) => {
        initialActivityStates[activity.id] = 2;
      });

      setActivities(filteredActivities);
      setActivityStates(initialActivityStates);
      setLoading(false);
    } catch (err) {
      setError(err.message || "Failed to fetch activities");
      setLoading(false);
    }
  };

  const handleSportTypeToggle = (sportType) => {
    const isTypeSelected = selectedSportTypes.includes(sportType);
    const updatedActivityStates = { ...activityStates };
    activities.forEach((activity) => {
      if (activity.sportType === sportType) {
        updatedActivityStates[activity.id] = isTypeSelected ? 0 : 2;
      }
    });

    setActivityStates(updatedActivityStates);
    setSelectedSportTypes((prev) =>
      isTypeSelected ? prev.filter((type) => type !== sportType) : [...prev, sportType]
    );
  };

  const handleAllToggle = () => {
    const isAllSelected = selectedSportTypes.length === allSportTypes.length;
    const updatedActivityStates = {};
    activities.forEach((activity) => {
      updatedActivityStates[activity.id] = isAllSelected ? 0 : 2;
    });

    setActivityStates(updatedActivityStates);
    setSelectedSportTypes(isAllSelected ? [] : allSportTypes);
  };

  const toggleUnitSystem = () => {
    setUnitSystem((prevUnit) => (prevUnit === "metric" ? "imperial" : "metric"));
  };

  const toggleCondensedView = () => {
    setIsCondensed((prev) => !prev); // 축약 상태 토글
  };

  const handleViewModeChange = (event, newView) => {
    if (newView !== null) {
      setViewMode(newView);
    }
  };

  const getSelectedTotalStats = () => {
    const selectedActivitiesList = activities.filter(
      (activity) => activityStates[activity.id] === 1 || activityStates[activity.id] === 2
    );

    const totalDistance = selectedActivitiesList.reduce(
      (total, activity) => total + activity.distance,
      0
    );
    const totalElevationGain = selectedActivitiesList.reduce(
      (total, activity) => total + activity.totalElevationGain,
      0
    );
    const totalMovingTime = selectedActivitiesList.reduce(
      (total, activity) => total + activity.movingTime,
      0
    );

    // 새로운 summaryPolylineBySport 구조
    const summaryPolylineBySport = {};

    allSportTypes.forEach((sportType) => {
      const filteredActivities = selectedActivitiesList.filter(
        (activity) => activity.sportType === sportType && activityStates[activity.id] === 2
      );

      // 거리 순으로 정렬한 후, polyline과 distance를 포함한 객체 배열로 저장
      summaryPolylineBySport[sportType] = filteredActivities
        .sort((a, b) => b.distance - a.distance)
        .map((activity) => ({
          polyline: activity.map.summaryPolyline,
          distance: activity.distance,
        }));
    });

    return {
      totalDistance,
      totalElevationGain,
      totalMovingTime,
      summaryPolylineBySport,
    };
  };

  const handleActivityStateChange = (activityId) => {
    setActivityStates((prevStates) => {
      const currentState = prevStates[activityId] !== undefined ? prevStates[activityId] : 2;
      const newState = currentState === 0 ? 2 : currentState - 1;
      return { ...prevStates, [activityId]: newState };
    });
  };

  const handleFontFamilyChange = (value) => {
    setFontFamily(value);
  };

  const handleFontColorSelect = (selectedColor) => {
    setFontColor(selectedColor);
  };

  const handleBgColorSelect = (selectedColor) => {
    setBgColor(selectedColor);
  };

  if (loading) return <CircularProgress />;
  if (error) return <Typography color="error">{error}</Typography>;

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Container maxWidth="lg">
        <Typography variant="h4" gutterBottom>
          Strava Activities
        </Typography>

        <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
          <Box>
            <Typography variant="body1">Select Month:</Typography>
              <Select
                value={dayjs(`${selectedMonth.year()}-${selectedMonth.month() + 1}`).format("YYYY-MM")}
                onChange={(e) => setSelectedMonth(dayjs(`${e.target.value}-01`))}
              >
                {[dayjs(), dayjs().subtract(1, "month"), dayjs().subtract(2, "month")].map(
                  (date) => (
                    <MenuItem
                      key={date.format("YYYY-MM")}
                      value={`${date.format("YYYY-MM")}`}
                    >
                      {date.format("MMMM YYYY")}
                    </MenuItem>
                  )
                )}
              </Select>
          </Box>

          <Box>
            <Typography variant="body1">Sport Type:</Typography>
            <Box>
              <Button
                variant={selectedSportTypes.length === allSportTypes.length ? "contained" : "outlined"}
                onClick={handleAllToggle}
                sx={{ margin: "0 5px" }}
              >
                All
              </Button>
              {allSportTypes.map((sportType) => (
                <Button
                  key={sportType}
                  variant={selectedSportTypes.includes(sportType) ? "contained" : "outlined"}
                  onClick={() => handleSportTypeToggle(sportType)}
                  sx={{ margin: "0 5px" }}
                >
                  {sportType}
                </Button>
              ))}
            </Box>
          </Box>

          <Button variant="contained" onClick={toggleUnitSystem}>
            {unitSystem === "metric" ? "Switch to Imperial" : "Switch to Metric"}
          </Button>
        </Box>

        <Box sx={{}}>
          <FontPicker
            fontFamily={fontFamily}
            handleFontFamilyChange={handleFontFamilyChange}
          />
          
          <AllColorPicker subTitle="Pick a Font Color" onColorSelect={handleFontColorSelect} />

          <FormControlLabel
            control={
              <Checkbox
                checked={applyFontColorToPolyline}
                onChange={(e) => setApplyFontColorToPolyline(e.target.checked)}
              />
            }
            label="Apply Font Color to Polyline"
            sx={{ mt: 1 }}
          />

          <AllColorPicker subTitle="Pick a Background Color" onColorSelect={handleBgColorSelect} />

        </Box>
        
        <Box 
          sx={{ 
            mt: 2, 
            textAlign: 'center', 
            fontFamily: fontFamily,
            color: fontColor,
          }}>
          <Box
            ref={componentRef} // 크기 확인을 위한 ref 추가
            sx={{
              width: "100%",
              height: size.width ? size.width : "200px",
              maxWidth: "500px",  // 최대 500px
              border: "1px solid #ccc",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "relative",
              margin: "0 auto", // 중앙 정렬
              backgroundColor: bgColor,
            }}
          >
            <Box sx={{ 
              position: "absolute",
              top: 0,
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              gap: 1,
              height: size.width * 0.3,
            }}>
              <Box variant="div" >
                <Box 
                  sx={{
                    fontSize: 24,
                  }}
                >
                  {selectedMonth.format("MMMM YYYY")}
                </Box>
              </Box>
              <Box variant="div" sx={{ 
                display: "flex", 
                alignItems: "center", 
                gap: 1,
                fontSize: 16,
              }}
              >
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <FaRuler/> 
                  <Typography variant="span">
                    {numberWithCommas(convertDistance(totalStats.totalDistance, unitSystem))}{" "}
                    {unitSystem === "metric" ? "km" : "mi"}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <FaMountain />
                  <Typography variant="span">
                    {numberWithCommas(convertElevation(totalStats.totalElevationGain, unitSystem))}{" "}
                    {unitSystem === "metric" ? "m" : "ft"}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <FaClock/>
                  <Typography variant="span">
                    {formatTime2(totalStats.totalMovingTime)}
                  </Typography>
                </Box>
              </Box>
            </Box>
            {(() => {
              // 부모 Box의 최대 500px 크기를 기준으로 90% 크기 계산
              const calculatedWidth = Math.min(size.width, 500) * 0.95;
              const calculatedHeight = Math.min(size.width, 500) * 0.7;
              return (
                <Box
                  sx={{
                    position: "absolute",
                    bottom: 0,
                  }}
                >
                  {viewMode === "polyline" ? (
                    <PolylineGroupToSVG
                      polylinesBySport={totalStats.summaryPolylineBySport}
                      width={calculatedWidth } // 계산된 크기 전달
                      height={calculatedHeight }
                      backgroundColor={bgColor}
                      strokeColor={applyFontColorToPolyline ?fontColor : undefined}
                    />
                  ) : (
                    <ActivityGrid
                      summaryPolylineBySport={totalStats.summaryPolylineBySport}
                      gridWidth={calculatedWidth }
                      gridHeight={calculatedHeight }
                      backgroundColor={bgColor}
                      strokeColor={applyFontColorToPolyline ?fontColor : undefined}
                    />
                  )}
                </Box>
              );
            })()}
          </Box>
        </Box>

        {/* 뷰 모드 토글 버튼 추가 */}
        <Box sx={{ mt: 2, display: "flex", justifyContent: "center" }}>
          <ToggleButtonGroup
            value={viewMode}
            exclusive
            onChange={handleViewModeChange}
            aria-label="view mode"
          >
            <ToggleButton value="polyline" aria-label="Polyline View">
              Polyline View
            </ToggleButton>
            <ToggleButton value="grid" aria-label="Grid View">
              Grid View
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>

        <Box sx={{ mt: 3 }}>
          <Button variant="contained" onClick={toggleCondensedView}>
            {isCondensed ? "Expand View" : "Condensed View"}
          </Button>
        </Box>

        <StatCalendar
          activities={activities}
          selectedMonth={selectedMonth}
          activityStates={activityStates}
          handleActivityStateChange={handleActivityStateChange}
          isMobile={isMobile}
          unitSystem={unitSystem}
          isCondensed={isCondensed} // 축약 여부 전달
          fontFamily={fontFamily}
        />
      </Container>
    </LocalizationProvider>
  );
}

export default StatMonthActivities;