import React, { useEffect, useState } from 'react';
import { Container, Typography, CircularProgress, Box, Button, Avatar } from '@mui/material';
import { fetchUserProfile, fetchStravaConnectUrl } from '../services/ApiService';
import { use } from 'i18next';

function Profile() {
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [stravaConnectUrl, setStravaConnectUrl] = useState(null);
  const [error, setError] = useState('');

  useEffect(() => {
    const loadProfile = async () => {
      try {
        const data = await fetchUserProfile();
        setProfile(data.response);
      } catch (err) {
        setError(err.message || 'Failed to fetch profile');
      } finally {
        setLoading(false);
      }
    };

    loadProfile();
  }, []);

  useEffect (() => {
    const getStravaConnectUrl = async () => {
      try {
        const data = await fetchStravaConnectUrl();
        // console.log(data);
        setStravaConnectUrl(data.response);
      } catch (err) {
        setError(err.message || 'Failed to fetch profile');
      } finally {
        setLoading(false);
      }
    };

    if (profile?.isStravaConnected === false) {
      getStravaConnectUrl();
    }
  }, [profile]);

  if (loading) return <CircularProgress />;
  if (error) return <Typography color="error">{error}</Typography>;

  return (
    <Container maxWidth="sm">
      <Box sx={{ mt: 5 }}>
        <Typography variant="h4" gutterBottom>
          Profile
        </Typography>
        <Typography variant="h6">Name: {profile.username}</Typography>
        <Typography variant="body1">Email: {profile.email}</Typography>

        <Box sx={{ mt: 5 }}>
        {
          profile?.isStravaConnected ? (
            <React.Fragment>
              {/* <Typography variant="body1">Strava: Connected</Typography> */}
  
              <Typography variant="h4" gutterBottom gutterTop>
                Strava Athlete Info
              </Typography>
              <Avatar
                alt={`${profile.athlete.firstname} ${profile.athlete.lastname}`}
                src={profile.athlete.profile}
                sx={{ width: 56, height: 56 }}
              />
              <Typography variant="body1">{profile.athlete.firstname} {profile.athlete.lastname}</Typography>
              <Typography variant="body1">{profile.athlete.bio}</Typography>
            </React.Fragment>

          ) : (
            <React.Fragment>
              <Typography variant="body1">Strava: Not connected</Typography>
              {
                stravaConnectUrl && (
                  <Button variant="contained" color="primary" sx={{ mt: 2 }}
                    href={stravaConnectUrl}
                  >
                    Connect with Strava
                  </Button>
                )
              }
            </React.Fragment>
          )
        }
        </Box>
      </Box>
    </Container>
  );
}

export default Profile;
