import React from 'react';
import { FormControl, Typography, Box, ToggleButton } from '@mui/material';

const TextColorPicker = ({ fontColorSet, textColor, handleTextColorChange }) => {
  return (
    <FormControl component="fieldset" sx={{ mt: 2 }}>
      <Typography variant="h6">Text Color</Typography>
      <Box>
        {fontColorSet.map((color) => (
          <ToggleButton
            key={color}
            value={color}
            selected={textColor === color}
            onClick={() => handleTextColorChange(color)}
            sx={{
              backgroundColor: color,
              border: `2px solid ${textColor === color ? '#000' : color === '#ffffff' ? '#eeeeee' : 'transparent'}`,
              mr: 1,
            }}
          />
        ))}
      </Box>
    </FormControl>
  );
};

export default TextColorPicker;