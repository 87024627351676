// util.js

/**
 * 초를 시간:분:초 형식으로 변환하는 함수
 * @param {number} seconds - 초 단위의 시간
 * @returns {string} 시간:분:초 형식의 문자열
 */
export function formatTime(seconds, isHide0Hour = false) {
  const hrs = Math.floor(seconds / 3600);
  const mins = Math.floor((seconds % 3600) / 60);
  const secs = Math.floor(seconds % 60);

  if(isHide0Hour && hrs === 0) {
    return `${mins.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
  }

  return `${hrs.toString().padStart(2, '0')}:${mins.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
}

/**
 * 초를 시간h 분m 형식으로 변환하는 함수
 * @param {number} seconds - 초 단위의 시간
 * @returns {string} 시간h 분m 형식의 문자열
 */
export function formatTime2 (seconds, isHide0Hour = false, isHideSecond = false) {
  const hrs = Math.floor(seconds / 3600);
  const mins = Math.floor((seconds % 3600) / 60);
  let returnStr = '';

  if(isHide0Hour && hrs === 0) {
    returnStr = `${mins}m`;
  } else {
    returnStr = `${hrs}h ${mins}m`;
  }

  if(!isHideSecond) {
    returnStr = `${returnStr} ${seconds % 60}s`;
  }

  return returnStr;
};

/**
* m/s 단위의 속도를 받아 100m를 이동하는 데 걸리는 시간을 초 단위로 계산하는 함수
* @param {number} speed - m/s 단위의 속도
* @returns {number} 100m를 이동하는 데 걸리는 시간 (초)
*/
export function calculateTimeFor100m(speed) {
  if (speed <= 0) {
      throw new Error("Speed must be greater than zero");
  }
  const distance = 100; // 100 meters
  const timeInSeconds = distance / speed; // 시간 = 거리 / 속도
  return timeInSeconds;
}

/**
* m/s 단위의 속도를 받아 100m를 이동하는 데 걸리는 시간을 초 단위로 계산하는 함수
* @param {number} speed - m/s 단위의 속도
* @returns {number} 100m를 이동하는 데 걸리는 시간 (초)
*/
export function calculateTimeFor1k(speed) {
  if (speed <= 0) {
      throw new Error("Speed must be greater than zero");
  }
  const distance = 1000; // 1000 meters
  const timeInSeconds = distance / speed; // 시간 = 거리 / 속도
  return timeInSeconds;
}

/**
 * 숫자에 천 단위 콤마를 추가하는 함수
 * @param {number} number - 콤마를 추가할 숫자
 * @returns {string} 천 단위 콤마가 추가된 문자열
 */
export function numberWithCommas(number, locale = 'en-US', fixed = 0) {
  // 입력된 값을 숫자로 변환한 후 소수점 자릿수 고정
  const parsedNumber = parseFloat(number);

  // 숫자가 아닌 값이 들어왔을 때 처리
  if (isNaN(parsedNumber)) {
    return 'Invalid number';
  }

  return Number(parsedNumber.toFixed(fixed)).toLocaleString(locale);
}

/**
 * ISO 8601 형식의 날짜 문자열을 로케일에 맞는 날짜 문자열로 변환하는 함수
 * @param {string} isoDateString - ISO 8601 형식의 날짜 문자열
 * @param {string} locale - 로케일 (기본값: 'default')
 * @param {Object} options - Intl.DateTimeFormat의 옵션
 * @returns {string} 로케일에 맞는 날짜 문자열
 */
export function formatLocaleDateWithTimezone(isoDateString, timezone, locale = 'default', options = {}) {
  // ISO 8601 문자열을 Date 객체로 변환
  const date = new Date(isoDateString);

  // 시간대 문자열에서 실제 시간대 ('Asia/Chita') 추출
  // const timeZoneMatch = timezone.match(/\(([^)]+)\) ([^/]+\/[^/]+)/);
  // const timeZone = timeZoneMatch ? timeZoneMatch[2] : 'UTC'; // 추출된 값이 없으면 기본값으로 'UTC' 사용

  // 기본 옵션 설정
  const defaultOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    // timeZoneName: 'short',
    // timeZone: timeZone
  };

  // 전달된 옵션이 있다면 기본 옵션과 병합
  const formatOptions = { ...defaultOptions, ...options };

  // 로케일에 맞는 날짜 형식으로 변환
  return new Intl.DateTimeFormat(locale, formatOptions).format(date);
}

export function convertDistance(distance, unitSystem) {
  return unitSystem === "metric"
    ? (distance / 1000)
    : (distance / 1609.34);
};

export function convertElevation(elevation, unitSystem) {
  return unitSystem === "metric"
    ? elevation
    : (elevation * 3.28084);
};

export function convertDistanceSwim(distance, unitSystem) {
  return unitSystem === "metric"
    ? distance
    : (distance * 1.09361);
}