import React, { useState, useRef, useEffect } from 'react';
import { Checkbox, Container, Button, Typography, Box, FormControl, FormControlLabel } from '@mui/material';
import { toPng } from 'html-to-image';
import { formatTime, calculateTimeFor100m, numberWithCommas, formatLocaleDateWithTimezone } from '../utils/stringUtils.js';

// contexts
import { useSwimDetailContext } from '../contexts/SwimDetailContext';

// components
import BackgroundColorPicker from './BackgroundColorPicker';
import TextColorPicker from './TextColorPicker';
import FontPicker from './FontPicker';
import AspectRatioPicker from './AspectRatioPicker';
import CropImageModal from './CropImageModal';
import SwimImagePreview from './SwimImagePreview.js';
import SwimChart from './SwimChart.js';
import TextLayoutPicker from './TextLayoutPicker.js';
import ChartColorPicker from './ChartColorPicker.js';

// icons
import { FaPersonSwimming } from "react-icons/fa6";
import { FaFire } from "react-icons/fa6";
import { FaStopwatch } from "react-icons/fa";
import { FaHeartbeat } from "react-icons/fa";
import { FaClock } from "react-icons/fa";
import { FaRuler } from "react-icons/fa";

const SwimDetail = ({ swimData, swimChartData, swimChartSimpleData }) => {
  const [image, setImage] = useState(null);
  const [cropData, setCropData] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  // const [aspectRatio, setAspectRatio] = useState(1); // 기본 비율 1:1
  // const [layoutEnabled, setLayoutEnabled] = useState(true); // 레이아웃 on/off 상태
  
  // const [backgroundColor, setBackgroundColor] = useState('#333333'); // 기본 배경 색상
  // const [textOption, setTextOption] = useState('option1'); // 선택된 텍스트 옵션
  // const [textColor, setTextColor] = useState('#ffffff'); // 텍스트 색상 기본값
  // const [backgroundEnabled, setBackgroundEnabled] = useState(false); // 텍스트 불투명 배경 on/off 상태
  // const [fontFamily, setFontFamily] = useState('Open Sans'); // 텍스트 폰트 기본값: Open Sans
  // const [dataText, setDataText] = useState(); // 데이터 텍스트
  
  // const [chartLayoutEnabled, setChartLayoutEnabled] = useState(true); // 차트 레이아웃 on/off 상태
  // const [simpleChartEnabled, setSimpleChartEnabled] = useState(false); // 심플 차트 변경
  // const [isHRcolor, setIsHRcolor] = useState(false); // 심박수 색상 변경

  //ref
  const cropperRef = useRef(null);
  const imageRef = useRef(null); // 이미지와 레이아웃 전체를 감싸는 요소의 참조

  const backgroundColorSet = [
    '#ffffff', '#333333', '#000000'
  ];

  const fontColorSet = [
    '#ffffff', '#000000'
  ];

  const textOptions = [
    { value: 'option1', label: 'Option 1' },
    { value: 'option2', label: 'Option 2' },
    { value: 'option3', label: 'Option 3' },
  ];

  const { 
    aspectRatio,
    layoutEnabled,
    dataText,
    backgroundColor,
    backgroundEnabled,
    textOption,
    textColor,
    fontFamily,
    chartLayoutEnabled,
    simpleChartEnabled,
    chartColorEnabled,
    maxHeartrateEnabled,
    avgHeartrateEnabled,
    avgSpeedEnabled,
    chartColorSet,

    setData,
    setAspectRatio,
    setLayoutEnabled,
    setDataText,
    setBackgroundColor,
    setBackgroundEnabled,
    setTextOption,
    setTextColor,
    setFontFamily,
    setChartLayoutEnabled,
    setSimpleChartEnabled,
    setChartColorEnabled,
    setSwimChartData,
    setSwimChartSimpleData,
    setMaxHeartrateEnabled,
    setAvgHeartrateEnabled,
    setAvgSpeedEnabled,
    setChartColorSet,

  } = useSwimDetailContext();

  const onChange = (e) => {
    e.preventDefault();
    const files = e.target.files;
    if (files && files.length > 0) {
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result);
        setModalOpen(true); // 이미지 업로드 시 모달 열기
      };
      reader.readAsDataURL(files[0]);
    }
  };

  const handleCrop = () => {
    if (cropperRef.current) {
      const croppedCanvas = cropperRef.current.cropper.getCroppedCanvas();
      setCropData(croppedCanvas.toDataURL());
      setModalOpen(false); // 크롭 후 모달 닫기
    }
  };

  const handleAspectRatioChange = (value) => {
    setAspectRatio(value);
  };

  const handleTextOptionChange = (value) => {
    setTextOption(value);
  };

  const handleTextColorChange = (value) => {
    setTextColor(value);
  };

  const handleFontFamilyChange = (value) => {
    setFontFamily(value);
  };

  const handleBackgroundColorChange = (value) => {
    setBackgroundColor(value);
  };

  const handleChartColorSetChange = (newSet) => {
    setChartColorSet(newSet);
  };

  const downloadImage = () => {
    if (imageRef.current) {
      toPng(imageRef.current)
        .then((dataUrl) => {
          const link = document.createElement('a');
          link.href = dataUrl;
          link.download = 'image-with-layout.png';
          link.click();
        })
        .catch((err) => {
          console.error('Error downloading the image:', err);
        });
    }
  };

  const createTextLayout = () => {
    let texts = [];
    // console.log(swimData);

    const distance = numberWithCommas(swimData.distance);
    const averageHeartrate = Math.round(swimData.averageHeartrate);
    const calories = numberWithCommas(swimData.calories);
    const movingTime = formatTime(swimData.movingTime);
    const elapsedTime = formatTime(swimData.elapsedTime);
    const averageSpeed = formatTime(calculateTimeFor100m(swimData.averageSpeed), true);
    const maxSpeed = formatTime(calculateTimeFor100m(swimData.maxSpeed), true);
    const swimPoolLength = swimData.swimPoolLength;
    const startDate = formatLocaleDateWithTimezone(swimData.startDate, swimData.timezone);
  
    if (swimData) {
      switch (textOption) {
        case 'option1':
          //////////////////////////////////////////////// option1
          const leftPosition1 = '5%';
          texts.push({
            text: [
              { content: <FaPersonSwimming  style={{ fontSize: '18px', marginRight: 8 }} />, fontSize: '' },
              { content: `${swimData.name}`, fontSize: '24px' },
            ],
            top: 'calc(5%)',
            left: leftPosition1,
            fontSize: '20px'
          });
          texts.push({
            text: [
              { content: ` ${startDate}`, fontSize: '14px' },
            ],
            top: 'calc(5% + 40px)',
            left: leftPosition1,
            fontSize: '20px'
          });
          texts.push({
            text: [
              {content: <FaRuler style={{ fontSize: '18px', marginRight: 8 }} />, fontSize: '' },
              // { content: `Distance: `, fontSize: '18px' },
              { content: `${distance}`, fontSize: '24px' },
              { content: ` m`, fontSize: '16px' },
            ],
            top: 'calc(5% + 80px)',
            left: leftPosition1,
            fontSize: '20px'
          });
          texts.push({
            text: [
              { content: <FaClock style={{ fontSize: '18px', marginRight: 8 }} />, fontSize: '' },
              // { content: `Time: `, fontSize: '18px' },
              { content: `${movingTime}`, fontSize: '24px' },
            ],
            top: 'calc(5% + 120px)',
            left: leftPosition1,
            fontSize: '20px'
          });
          texts.push({
            text: [
              { content: <FaHeartbeat style={{ fontSize: '18px', marginRight: 8 }} />, fontSize: '' },
              // { content: `HR: `, fontSize: '18px' },
              { content: `${averageHeartrate}`, fontSize: '24px' },
              { content: ` bpm `, fontSize: '18px' },
            ],
            top: 'calc(5% + 160px)',
            left: leftPosition1,
            fontSize: '20px'
          });
          texts.push({
            text: [
              { content: <FaStopwatch style={{ fontSize: '18px', marginRight: 8 }} />, fontSize: '' },
              // { content: `pace: `, fontSize: '18px' },
              { content: `${averageSpeed}`, fontSize: '24px' },
              { content: ` /100m`, fontSize: '16px' },
            ],
            top: 'calc(5% + 200px)',
            left: leftPosition1,
            fontSize: '20px'
          });
          texts.push({
            text: [
              { content: <FaFire style={{ fontSize: '18px', marginRight: 8 }} />, fontSize: '' },
              // { content: `Calories: `, fontSize: '20px' },
              { content: `${calories}`, fontSize: '26px' },
              { content: ` kcal`, fontSize: '16px' },
            ],
            top: 'calc(5% + 240px)',
            left: leftPosition1,
            fontSize: '20px'
          });
          break;
        case 'option2':
          //////////////////////////////////////////////// option2
          const leftPosition2 = '50%';
          const transform2 = 'translate(-50%, -50%)';
          texts.push({
            text: [
              { content: <FaPersonSwimming  style={{ fontSize: '18px', marginRight: 8 }} />, fontSize: '' },
              { content: `${swimData.name}`, fontSize: '24px' },
            ],
            top: 'calc(5%)',
            left: leftPosition2,
            transform: transform2,
            fontSize: '20px'
          });
          texts.push({
            text: [
              { content: ` ${startDate}`, fontSize: '14px' },
            ],
            top: 'calc(5% + 40px)',
            left: leftPosition2,
            transform: transform2,
            fontSize: '20px'
          });
          texts.push({
            text: [
              {content: <FaRuler style={{ fontSize: '18px', marginRight: 8 }} />, fontSize: '' },
              // { content: `Distance: `, fontSize: '18px' },
              { content: `${distance}`, fontSize: '24px' },
              { content: ` m`, fontSize: '16px' },
            ],
            top: 'calc(5% + 80px)',
            left: leftPosition2,
            transform: transform2,
            fontSize: '20px'
          });
          texts.push({
            text: [
              { content: <FaClock style={{ fontSize: '18px', marginRight: 8 }} />, fontSize: '' },
              // { content: `Time: `, fontSize: '18px' },
              { content: `${movingTime}`, fontSize: '24px' },
            ],
            top: 'calc(5% + 120px)',
            left: leftPosition2,
            transform: transform2,
            fontSize: '20px'
          });
          texts.push({
            text: [
              { content: <FaHeartbeat style={{ fontSize: '18px', marginRight: 8 }} />, fontSize: '' },
              // { content: `HR: `, fontSize: '18px' },
              { content: `${averageHeartrate}`, fontSize: '24px' },
              { content: ` bpm `, fontSize: '18px' },
            ],
            top: 'calc(5% + 160px)',
            left: leftPosition2,
            transform: transform2,
            fontSize: '20px'
          });
          texts.push({
            text: [
              { content: <FaStopwatch style={{ fontSize: '18px', marginRight: 8 }} />, fontSize: '' },
              // { content: `pace: `, fontSize: '18px' },
              { content: `${averageSpeed}`, fontSize: '24px' },
              { content: ` /100m`, fontSize: '16px' },
            ],
            top: 'calc(5% + 200px)',
            left: leftPosition2,
            transform: transform2,
            fontSize: '20px'
          });
          texts.push({
            text: [
              { content: <FaFire style={{ fontSize: '18px', marginRight: 8 }} />, fontSize: '' },
              // { content: `Calories: `, fontSize: '20px' },
              { content: `${calories}`, fontSize: '26px' },
              { content: ` kcal`, fontSize: '16px' },
            ],
            top: 'calc(5% + 240px)',
            left: leftPosition2,
            transform: transform2,
            fontSize: '20px'
          });
          break;
        case 'option3':
          //////////////////////////////////////////////// option1
          const rightPosition3 = '5%';
          texts.push({
            text: [
              { content: <FaPersonSwimming  style={{ fontSize: '18px', marginRight: 8 }} />, fontSize: '' },
              { content: `${swimData.name}`, fontSize: '24px' },
            ],
            top: 'calc(5%)',
            right: rightPosition3,
            fontSize: '20px'
          });
          texts.push({
            text: [
              { content: ` ${startDate}`, fontSize: '14px' },
            ],
            top: 'calc(5% + 40px)',
            right: rightPosition3,
            fontSize: '20px'
          });
          texts.push({
            text: [
              {content: <FaRuler style={{ fontSize: '18px', marginRight: 8 }} />, fontSize: '' },
              // { content: `Distance: `, fontSize: '18px' },
              { content: `${distance}`, fontSize: '24px' },
              { content: ` m`, fontSize: '16px' },
            ],
            top: 'calc(5% + 80px)',
            right: rightPosition3,
            fontSize: '20px'
          });
          texts.push({
            text: [
              { content: <FaClock style={{ fontSize: '18px', marginRight: 8 }} />, fontSize: '' },
              // { content: `Time: `, fontSize: '18px' },
              { content: `${movingTime}`, fontSize: '24px' },
            ],
            top: 'calc(5% + 120px)',
            right: rightPosition3,
            fontSize: '20px'
          });
          texts.push({
            text: [
              { content: <FaHeartbeat style={{ fontSize: '18px', marginRight: 8 }} />, fontSize: '' },
              // { content: `HR: `, fontSize: '18px' },
              { content: `${averageHeartrate}`, fontSize: '24px' },
              { content: ` bpm `, fontSize: '18px' },
            ],
            top: 'calc(5% + 160px)',
            right: rightPosition3,
            fontSize: '20px'
          });
          texts.push({
            text: [
              { content: <FaStopwatch style={{ fontSize: '18px', marginRight: 8 }} />, fontSize: '' },
              // { content: `pace: `, fontSize: '18px' },
              { content: `${averageSpeed}`, fontSize: '24px' },
              { content: ` /100m`, fontSize: '16px' },
            ],
            top: 'calc(5% + 200px)',
            right: rightPosition3,
            fontSize: '20px'
          });
          texts.push({
            text: [
              { content: <FaFire style={{ fontSize: '18px', marginRight: 8 }} />, fontSize: '' },
              // { content: `Calories: `, fontSize: '20px' },
              { content: `${calories}`, fontSize: '26px' },
              { content: ` kcal`, fontSize: '16px' },
            ],
            top: 'calc(5% + 240px)',
            right: rightPosition3,
            fontSize: '20px'
          });
          break;
        default:
          break;
      }
    }
  
    setDataText(texts);
  };

  const getBoxDimensions = () => {
    const widthSize = 500;
    if (aspectRatio === 1) {
      return { width: widthSize, height: widthSize }; // 1:1 비율
    } else if (aspectRatio === 9 / 16) {
      return { width: widthSize, height: (widthSize / 9) * 16 }; // 9:16 비율
    } else {
      return { width: widthSize, height: widthSize }; // 기본값 (1:1)
    }
  };

  const { width, height } = getBoxDimensions();

  useEffect(() => {
    if(swimData) {
      setData(swimData);
      setSwimChartData(swimChartData);
      setSwimChartSimpleData(swimChartSimpleData);
      createTextLayout();
    }
  } , [swimData, textOption]);

  return (
    <Container>
      <Typography variant="h4" align="center" gutterBottom>
        <FaPersonSwimming />
      </Typography>

      <AspectRatioPicker
        aspectRatio={aspectRatio}
        handleAspectRatioChange={handleAspectRatioChange}
      />

      <input type="file" accept="image/*" onChange={onChange} />

      <BackgroundColorPicker
        backgroundColorSet={backgroundColorSet}
        backgroundColor={backgroundColor}
        handleBackgroundColorChange={handleBackgroundColorChange}
      />

      <TextColorPicker
        fontColorSet={fontColorSet}
        textColor={textColor}
        handleTextColorChange={handleTextColorChange}
      />

      <FontPicker
        fontFamily={fontFamily}
        handleFontFamilyChange={handleFontFamilyChange}
      />

      <TextLayoutPicker
        textOptions={textOptions}
        textOption={textOption}
        handleTextOptionChange={handleTextOptionChange}
      />

      <FormControl component="fieldset" sx={{ mt: 2, width: '100%' }}>

        <FormControlLabel
          control={<Checkbox checked={layoutEnabled} onChange={(e) => setLayoutEnabled(e.target.checked)} />}
          label="Enable Layout"
          sx={{ mt: 1 }}
        />
        
        <FormControlLabel
          control={<Checkbox checked={backgroundEnabled} onChange={(e) => setBackgroundEnabled(e.target.checked)} />}
          label="Enable Text Background"
          sx={{ mt: 1 }}
        />

      </FormControl>

      <FormControl component="fieldset" sx={{ mt: 2, width: '100%' }}>
        <FormControlLabel
          control={<Checkbox checked={chartLayoutEnabled} onChange={(e) => setChartLayoutEnabled(e.target.checked)} />}
          label="Enable Chart Layout"
          sx={{ mt: 1 }}
        />
        <FormControlLabel
          control={<Checkbox checked={simpleChartEnabled} onChange={(e) => setSimpleChartEnabled(e.target.checked)} />}
          label="Simple Chart"
          sx={{ mt: 1 }}
        />

        <FormControlLabel
          control={<Checkbox checked={chartColorEnabled} onChange={(e) => setChartColorEnabled(e.target.checked)} />}
          label="HR Color"
          sx={{ mt: 1 }}
        />

        {
          chartColorEnabled &&
          <ChartColorPicker chartColorSet={chartColorSet} handleChartColorSetChange={handleChartColorSetChange} />
        }

        <FormControlLabel
          control={<Checkbox checked={maxHeartrateEnabled} onChange={(e) => setMaxHeartrateEnabled(e.target.checked)} />}
          label="Max HR"
          sx={{ mt: 1 }}
        />

        <FormControlLabel
          control={<Checkbox checked={avgHeartrateEnabled} onChange={(e) => setAvgHeartrateEnabled(e.target.checked)} />}
          label="Average HR Line"
          sx={{ mt: 1 }}
        />

        <FormControlLabel
          control={<Checkbox checked={avgSpeedEnabled} onChange={(e) => setAvgSpeedEnabled(e.target.checked)} />}
          label="Average Speed Line"
          sx={{ mt: 1 }}
        />
        
      </FormControl>

      <CropImageModal
        image={image}
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        cropperRef={cropperRef}
        handleCrop={handleCrop}
        aspectRatio={aspectRatio}
      />

      {dataText &&
        <SwimImagePreview
          imageRef={imageRef}
          width={width}
          height={height}
          cropData={cropData}
          SwimChartComponent={SwimChart}
        />
      }

      <Button variant="contained" color="secondary" onClick={downloadImage} sx={{ mt: 2 }}>
        📸
      </Button>
    </Container>
  );
};

export default SwimDetail;