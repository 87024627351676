import React from "react";
import { Grid, Typography, Box } from "@mui/material";
import dayjs from "dayjs";
import { PolylineToSVG } from "../components/Polyline";
import ActivityStyles from "../components/ActivityStyles";
import ActivityIcon from "../components/ActivityIcon";

import { formatTime, convertDistance, convertDistanceSwim, numberWithCommas } from "../utils/stringUtils";

const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]; // 요일 배열

const StatCalendar = ({
  activities,
  selectedMonth,
  activityStates,
  handleActivityStateChange,
  isMobile,
  unitSystem,
  isCondensed,
  fontFamily,
}) => {
  const startOfMonth = selectedMonth.startOf("month");
  const endOfMonth = selectedMonth.endOf("month");

  // 달력의 첫 주는 일요일부터 시작 (startDay = 0)
  const startDay = 0;
  const daysInMonth = [];

  // 달력의 첫 주 공백 채우기
  let day = startOfMonth.startOf("week");

  // 첫 주의 공백을 넣기
  while (day.isBefore(startOfMonth)) {
    daysInMonth.push({ day, isCurrentMonth: false });
    day = day.add(1, "day");
  }

  // 이번 달의 모든 날짜 추가
  while (day.isBefore(endOfMonth) || day.isSame(endOfMonth, "day")) {
    daysInMonth.push({ day, isCurrentMonth: true });
    day = day.add(1, "day");
  }

  // 마지막 주의 공백 채우기
  while (day.day() !== startDay) {
    daysInMonth.push({ day, isCurrentMonth: false });
    day = day.add(1, "day");
  }

  return (
    <>
      {/* 요일 헤더 */}
      <Grid container spacing={0} sx={{ width: "100%", mt: 2 }}>
        {daysOfWeek.map((day, index) => (
          <Grid
            item
            key={index}
            sx={{
              width: "calc(100% / 7)",
              minHeight: "40px",
              textAlign: "center",
              backgroundColor: "#f0f0f0",
              padding: { xs: "8px 0", sm: "8px 0" },
              borderBottom: "2px solid #ccc",
            }}
          >
            <Typography variant="body2" sx={{ fontWeight: "bold" }}>
              {day}
            </Typography>
          </Grid>
        ))}
      </Grid>

      {/* 달력 날짜 */}
      <Grid container spacing={0} sx={{ width: "100%", mt: 2 }}>
        {daysInMonth.map(({ day, isCurrentMonth }, index) => {
          const dayActivities = activities.filter(
            (activity) => dayjs(activity.startDate).startOf("day").isSame(day.startOf("day"))
          );
          return (
            <Grid
              item
              key={index}
              sx={{
                width: "calc(100% / 7)",
                minHeight: { xs: "100px", sm: "120px", md: "120px" },
                borderBottom: "1px solid #ccc",
                backgroundColor: isCurrentMonth ? "#fff" : "#f0f0f0",
                position: "relative",
                padding: { xs: "4px 1px", sm: "8px 2px", md: "8px 4px" },
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  position: "absolute",
                  top: { xs: 4, md: 8 },
                  left: { xs: 4, md: 8 },
                  fontSize: { xs: "0.75rem", sm: "0.875rem", md: "1rem" },
                  fontFamily: fontFamily,
                }}
              >
                {day.date()}
              </Typography>
              <Box sx={{ mt: { xs: 3, md: 3 } }}>
                {dayActivities.length > 0 ? (
                  dayActivities.map((activity) => {
                    const iconSize = isMobile ? 20 : 30;
                    const { borderColor } = ActivityStyles(activity.sportType);
                    const activityState = activityStates[activity.id] ?? 0; // 상태 기본값 0

                    // 상태에 따른 스타일 처리
                    let strokeColor, iconColor, textColor;
                    if (activityState === 0) {
                      // 지도, 글씨, 아이콘, 테두리 모두 회색 처리 (상태 2)
                      strokeColor = "#ddd";
                      iconColor = "#ddd";
                      textColor = "#ddd";
                    } else if (activityState === 1) {
                      // 지도만 회색 처리 (상태 1)
                      strokeColor = "#ddd";
                      iconColor = borderColor;
                      textColor = borderColor;
                    } else {
                      // 기본 상태 (선택됨)
                      strokeColor = borderColor;
                      iconColor = borderColor;
                      textColor = borderColor;
                    }

                    return (
                      <Box
                        key={activity.id}
                        onClick={() => handleActivityStateChange(activity.id)}
                        sx={{
                          mt: { xs: 0.5, sm: 2 },
                          borderRadius: "8px",
                          cursor: "pointer",
                          border: `2px solid ${strokeColor}`,
                          borderBottom: `4px solid ${strokeColor}`,
                          position: "relative",
                          transition: "border 0.3s ease",
                          padding: { xs: "1px", sm: 1, md: 2 },
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: { xs: "row", sm: "column" },
                            alignItems: "center",
                            flexWrap: "wrap",
                            justifyContent: "space-around",
                            alignContent: "center",
                            lineHeight: 0,
                            marginTop: "1px",
                          }}
                        >
                          <Box
                            sx={{
                              position: { xs: "relative", sm: "absolute" },
                              top: { xs: 0, sm: -15 },
                              left: { xs: 0, sm: "calc(50% - 12px);" },
                              width: { xs: 20, sm: 24 },
                              height: { xs: 20, sm: 20 },
                              textAlign: { xs: "center", sm: "center" },
                              backgroundColor: "#fff",
                            }}
                          >
                            <ActivityIcon
                              sportType={activity.sportType}
                              iconSize={isMobile ? 14 : 20}
                              iconColor={iconColor} // 상태에 따른 아이콘 색상 처리
                            />
                          </Box>
                          {!isCondensed && (
                            <PolylineToSVG
                              width={iconSize}
                              height={iconSize}
                              polyline={activity.map.summaryPolyline}
                              backgroundColor={strokeColor} // 상태에 따른 지도 색상 처리
                            />
                          )}
                          <Box
                            component="div"
                            sx={{
                              textAlign: "center",
                              mt: { xs: 0, sm: 0 },
                              width: "100%",
                              lineHeight: 0,
                            }}
                          >
                            <Typography
                              component="span"
                              variant="caption"
                              sx={{
                                fontSize: { xs: "0.6rem", sm: "0.8rem" },
                                mt: { xs: 0, sm: 0 },
                                color: textColor, // 상태에 따른 글씨 색상 처리
                                fontFamily: fontFamily,
                              }}
                            >
                              { activity.sportType === "Swim" ?
                                numberWithCommas(convertDistanceSwim(activity.distance, unitSystem)) :
                                numberWithCommas(convertDistance(activity.distance, unitSystem), 2)
                              }
                            </Typography>
                            <Typography
                              component="span"
                              variant="caption"
                              sx={{
                                fontSize: { xs: "0.55rem", sm: "0.8rem" },
                                mt: { xs: 0, sm: 0 },
                                color: textColor, // 상태에 따른 글씨 색상 처리
                                fontFamily: fontFamily,
                              }}
                            >
                              {activity.sportType === "Swim" ? (unitSystem === "metric" ? "m" : "yd") :
                                unitSystem === "metric" ? "km" : "mi"
                              }
                            </Typography>
                          </Box>
                          {!isCondensed && (
                            <Typography
                              variant="caption"
                              sx={{
                                fontSize: { xs: "0.55rem", sm: "0.8rem" },
                                mt: { xs: 0, sm: 0 },
                                color: textColor,
                                fontFamily: fontFamily,
                              }}
                            >
                              {formatTime(activity.movingTime, false)}
                            </Typography>
                          )}
                        </Box>
                      </Box>
                    );
                  })
                ) : (
                  <Typography variant="caption" color="textSecondary">
                    &nbsp;
                  </Typography>
                )}
              </Box>
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

export default StatCalendar;