import { useState, useEffect, useRef } from 'react';

function useComponentSize() {
  const [size, setSize] = useState({ width: 0, height: 0 });
  const componentRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      if (componentRef.current) {
        const { width, height } = componentRef.current.getBoundingClientRect();
        setSize({ width, height });
      }
    };

    // 렌더링이 끝난 후에 처음 크기를 가져오도록 함
    const initialResize = () => {
      requestAnimationFrame(handleResize);
    };

    initialResize(); // 처음 렌더링 시 크기 설정
    window.addEventListener('resize', handleResize); // 윈도우 리사이즈 시 호출
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return [componentRef, size];
}

export default useComponentSize;