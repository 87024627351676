import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from './locales/en/translation.json';
import translationKO from './locales/ko/translation.json';

// 번역 파일을 추가합니다.
const resources = {
  en: {
    translation: translationEN,
  },
  ko: {
    translation: translationKO,
  },
};

i18n
  .use(initReactI18next) // react-i18next와 연결합니다.
  .init({
    resources,
    lng: 'en', // 기본 언어를 설정합니다.
    fallbackLng: 'en', // 지원하지 않는 언어를 요청할 때 사용할 언어
    interpolation: {
      escapeValue: false, // XSS를 방지하기 위한 이스케이프 처리 비활성화 (React는 자동으로 처리)
    },
  });

export default i18n;
