import * as React from 'react';
import { Box, FormControl, InputLabel, MenuItem, Select, Typography, Grid } from '@mui/material'

// Google Fonts의 폰트들을 사용할 수 있도록 import
import '@fontsource/open-sans';
import '@fontsource/montserrat';
import '@fontsource/merriweather';
import '@fontsource/roboto-mono';
import '@fontsource/fira-code';
import '@fontsource/raleway';
import '@fontsource/roboto-slab';
import '@fontsource/josefin-sans';
import '@fontsource/dm-serif-text';
import '@fontsource/smooch';
import '@fontsource/orbitron';
import '@fontsource/caveat';
import '@fontsource/syncopate';
import '@fontsource/bungee';
import '@fontsource/mansalva';
import '@fontsource/jua';
import '@fontsource/vt323';
import '@fontsource/silkscreen';
import '@fontsource/dangrek';

const fonts = [
  'Open Sans',
  'Montserrat',
  'Merriweather',
  'Roboto Mono',
  'Fira Code',
  'Raleway',
  'Roboto Slab',
  'Josefin Sans',
  'DM Serif Text',
  'Smooch',
  'Orbitron',
  'Caveat',
  'Syncopate',
  'Bungee',
  'Mansalva',
  'Jua',
  'VT323',
  'Silkscreen',
  'Dangrek',
];

const FontPicker = ({ fontFamily, handleFontFamilyChange }) => {
  const [selectedFont, setSelectedFont] = React.useState(fontFamily);

  const handleChange = (event) => {
    const font = event.target.value;
    setSelectedFont(font);
    handleFontFamilyChange(font);
  };

  // selectedFont 값을 그대로 반환하는 함수
  const renderValue = (selected) => {
    return selected;
  };

  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Font Family</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={selectedFont}
          label="Font Family"
          onChange={handleChange}
          renderValue={renderValue} // renderValue prop에 함수 전달
          sx={{
            fontFamily: selectedFont,
          }}
        >
          {fonts.map((font) => (
            <MenuItem key={font} value={font} sx={{ fontFamily: font }}>
              {font}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}

export default FontPicker;