import React from 'react';
import ActivityStyles from './ActivityStyles';
import { PiEmpty } from "react-icons/pi";

export function decodePolyline(encoded) {
  // 이스케이프된 문자 처리
  encoded = encoded.replace(/\\\\/g, '\\');
  var points = [];
  var index = 0,
    len = encoded.length;
  var lat = 0,
    lng = 0;

  while (index < len) {
    var b,
      shift = 0,
      result = 0;
    do {
      b = encoded.charCodeAt(index++) - 63;
      result |= (b & 0x1f) << shift;
      shift += 5;
    } while (b >= 0x20);
    var dlat = result & 1 ? ~(result >> 1) : result >> 1;
    lat += dlat;

    shift = 0;
    result = 0;
    do {
      b = encoded.charCodeAt(index++) - 63;
      result |= (b & 0x1f) << shift;
      shift += 5;
    } while (b >= 0x20);
    var dlng = result & 1 ? ~(result >> 1) : result >> 1;
    lng += dlng;

    points.push({ lat: lat / 1e5, lng: lng / 1e5 });
  }
  return points;
}

export const PolylineToSVG = ({
  polyline,
  width,
  height,
  backgroundColor = '#576618',
  strokeColor = '#FFFFFF',
  strokeWidth = -1,
  style
}) => {
  // polyline이 없으면 "No Map" 텍스트를 가운데 흰색으로 표시
  if (!polyline || polyline.length === 0) {
    return (
      <svg
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        style={style}
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          width="100%"
          height="100%"
          fill={backgroundColor} // 배경색 동일하게 적용
          rx="20%"
          ry="20%"
        />
        <text
          x="50%"
          y="50%"
          alignmentBaseline="middle"
          textAnchor="middle"
          fill="#FFFFFF" // 흰색 글씨
          fontSize={width / 5} // 폰트 크기 조정 (SVG 너비의 1/5)
          fontFamily="Arial, sans-serif" // 적당한 폰트 패밀리 사용
        >
          No Data
        </text>
      </svg>
    );
  }
  
  const originalPoints = decodePolyline(polyline);
  const points = originalPoints.filter((_, index) => index % 5 === 0);

  let minLat = Math.min(...points.map(p => p.lat));
  let maxLat = Math.max(...points.map(p => p.lat));
  let minLng = Math.min(...points.map(p => p.lng));
  let maxLng = Math.max(...points.map(p => p.lng));

  const svgWidth = width;
  const svgHeight = height;
  const padding = width / 10;
  const svgStrokeWidth = strokeWidth === -1 ? (width / 30).toFixed(0) : strokeWidth;
  const radius = '20%';

  let lngRange = maxLng - minLng;
  let latRange = maxLat - minLat;

  // 패딩을 고려한 실제 그리기 영역의 크기
  let drawWidth = svgWidth - padding * 2;
  let drawHeight = svgHeight - padding * 2;

  let scaleX = drawWidth / lngRange;
  let scaleY = drawHeight / latRange;

  let scale = Math.min(scaleX, scaleY);

  // 중앙 정렬을 위한 추가 계산
  let offsetX = (svgWidth - lngRange * scale) / 2;
  let offsetY = (svgHeight - latRange * scale) / 2;

  // path의 시작점 조정
  let path =
    'M' +
    points
      .map(
        p =>
          `${Math.round((p.lng - minLng) * scale + offsetX)},${Math.round(
            svgHeight - (p.lat - minLat) * scale - offsetY
          )}`
      )
      .join(' L');

  return (
    <svg
      width={svgWidth}
      height={svgHeight}
      viewBox={`0 0 ${svgWidth} ${svgHeight}`}
      style={style}
      xmlns="http://www.w3.org/2000/svg">
      <rect
        width="100%"
        height="100%"
        fill={backgroundColor}
        rx={radius}
        ry={radius}
      />
      {/* 패딩을 고려하여 path 그리기 */}
      <path
        d={path}
        fill="none"
        stroke={strokeColor}
        strokeWidth={svgStrokeWidth}
      />
    </svg>
  );
};

export const PolylineGroupToSVG = ({ polylinesBySport, width, height, backgroundColor = '#fff', strokeColor }) => {
  const padding = 10;

  // 모든 polyline의 최소/최대 lat, lng 값을 계산하여 전체 범위를 구함
  const allPoints = Object.keys(polylinesBySport).flatMap((sportType) =>
    polylinesBySport[sportType].flatMap(({ polyline }) => decodePolyline(polyline))
  );

  if (allPoints.length === 0) return <svg width={width} height={height} />; // 폴리라인이 없을 경우 빈 SVG 반환

  let minLat = Math.min(...allPoints.map((p) => p.lat));
  let maxLat = Math.max(...allPoints.map((p) => p.lat));
  let minLng = Math.min(...allPoints.map((p) => p.lng));
  let maxLng = Math.max(...allPoints.map((p) => p.lng));

  // 전체 polyline 범위를 기준으로 스케일링을 계산
  let latRange = maxLat - minLat;
  let lngRange = maxLng - minLng;

  const drawWidth = width - padding * 2;
  const drawHeight = height - padding * 2;

  const scale = Math.min(drawWidth / lngRange, drawHeight / latRange);

  // 중앙 배치를 위한 offset
  const offsetX = (drawWidth - lngRange * scale) / 2 + padding;
  const offsetY = (drawHeight - latRange * scale) / 2 + padding;

  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="100%" height="100%" fill={backgroundColor} />

      {Object.keys(polylinesBySport).map((sportType) =>
        polylinesBySport[sportType].map(({ polyline }, index) => {
          const points = decodePolyline(polyline);

          // 각 point를 화면에 맞게 변환하고, 중앙 배치
          const path = points
            .map(
              (p) =>
                `${((p.lng - minLng) * scale + offsetX).toFixed(2)},${(
                  height -
                  ((p.lat - minLat) * scale + offsetY)
                ).toFixed(2)}`
            )
            .join(" L");

            // 각 sportType 별로 색상 지정
            const borderColor = strokeColor ? strokeColor : ActivityStyles(sportType).borderColor;
            
          return (
            <path
              key={index}
              d={`M${path}`}
              fill="none"
              stroke={borderColor}
              strokeWidth="2"
            />
          );
        })
      )}
    </svg>
  );
};