import React from 'react';
import { FormControl, Typography, Box, ToggleButton } from '@mui/material';

const BackgroundColorPicker = ({ backgroundColorSet, backgroundColor, handleBackgroundColorChange }) => {
  return (
    <FormControl component="fieldset" sx={{ mt: 2 }}>
      <Typography variant="h6">Background Color</Typography>
      <Box>
        {backgroundColorSet.map((color) => (
          <ToggleButton
            key={color}
            value={color}
            selected={backgroundColor === color}
            onClick={() => handleBackgroundColorChange(color)}
            sx={{
              backgroundColor: color,
              border: `2px solid ${backgroundColor === color ? '#000' : color === '#ffffff' ? '#eeeeee' : 'transparent'}`,
              mr: 1,
            }}
          />
        ))}
      </Box>
    </FormControl>
  );
};

export default BackgroundColorPicker;