import React from "react";
import { Box, Tooltip } from "@mui/material"; // Tooltip 컴포넌트 추가
import { PolylineToSVG } from "./Polyline";
import ActivityStyles from "./ActivityStyles"; // 운동 타입에 따른 스타일 가져오기

const ActivityGrid = ({ summaryPolylineBySport, gridWidth, gridHeight, backgroundColor = '#fff', strokeColor }) => {
  const gapSize = 2; // 각 항목 사이의 간격

  // 모든 활동을 거리 순으로 정렬한 배열을 생성
  const sortedActivities = Object.keys(summaryPolylineBySport)
    .flatMap((sportType) =>
      summaryPolylineBySport[sportType].map((activity) => ({
        sportType,
        polyline: activity.polyline,
        distance: activity.distance,
      }))
    )
    .filter((activity) => activity.polyline) // polyline이 없는 것 제거
    .sort((a, b) => b.distance - a.distance); // 거리 순으로 정렬

  // itemsPerRow는 필요한 최소한의 행 수에 맞게 제곱이 sortedActivities.length보다 큰 값으로 설정
  const itemsPerRow = Math.ceil(Math.sqrt(sortedActivities.length));

  // 박스 크기를 `gridWidth`와 `gridHeight`에 맞춰 조정
  const boxSizeWidth = (gridWidth - (itemsPerRow - 1) * gapSize) / itemsPerRow; // 가로 크기 계산
  const boxSizeHeight = (gridHeight - (itemsPerRow - 1) * gapSize) / itemsPerRow; // 세로 크기 계산
  const boxSize = Math.min(boxSizeWidth, boxSizeHeight); // 최종 박스 크기 결정 (가로/세로 중 작은 값 선택)

  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        gap: `${gapSize}px`, // SVG 간격을 2px로 고정
        width: `${gridWidth}px`, // 전달된 gridWidth 사용
        height: `${gridHeight}px`, // 전달된 gridHeight 사용
        alignItems: "center", 
        alignContent: "space-around", 
        justifyContent: "center",
      }}
    >
      {sortedActivities.map((activity, index) => {
        const borderColor = strokeColor ? strokeColor : ActivityStyles(activity.sportType).borderColor;

        // Tooltip 컴포넌트로 감싸기
        return (
          <Tooltip key={index} title={`유형: ${activity.sportType}\n거리: ${activity.distance.toFixed(2)}km`}> 
            <Box
              sx={{
                width: `${boxSize}px`,
                height: `${boxSize}px`,
                backgroundColor: backgroundColor, // 운동 타입에 따른 배경색 설정
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "8px",
              }}
            >
              <PolylineToSVG
                polyline={activity.polyline}
                width={boxSize * 0.8} // 크기를 박스 크기의 80%로 설정
                height={boxSize * 0.8}
                strokeColor={borderColor} // 운동 타입에 따른 선 색상 설정
                backgroundColor={backgroundColor} // 운동 타입에 따른 배경색 설정
              />
            </Box>
          </Tooltip>
        );
      })}
    </Box>
  );
};

export default ActivityGrid;