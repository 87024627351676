// RunDetailContext.js
import React, { createContext, useContext, useState } from 'react';
import { colorSetByDefault } from '../components/ChartColorPicker';

const RunDetailContext = createContext();

export const useRunDetailContext = () => {
  return useContext(RunDetailContext);
};

export const RunChartProvider = ({ children }) => {
  const [data, setData] = useState([]);

  const [aspectRatio, setAspectRatio] = useState(1); // 기본 비율 1:1
  const [layoutEnabled, setLayoutEnabled] = useState(true); // 레이아웃 on/off 상태
  const [dataText, setDataText] = useState(); // 데이터 텍스트
  
  const [backgroundColor, setBackgroundColor] = useState('#333333'); // 기본 배경 색상
  const [backgroundEnabled, setBackgroundEnabled] = useState(false); // 텍스트 불투명 배경 on/off 상태
  
  const [textOption, setTextOption] = useState('option1'); // 선택된 텍스트 옵션
  const [textColor, setTextColor] = useState('#ffffff'); // 텍스트 색상 기본값
  const [fontFamily, setFontFamily] = useState('Open Sans'); // 텍스트 폰트 기본값: Open Sans
  
  // chart
  const [runChartData, setRunChartData] = useState([]); // 런닝 데이터

  const [showChartType, setShowChartType] = useState('distance'); // 차트 타입
  const [chartLayoutEnabled, setChartLayoutEnabled] = useState(true); // 차트 레이아웃 on/off 상태
  const [maxHeartrateEnabled, setMaxHeartrateEnabled] = useState(false); // 최대 심박수 표시
  const [heartrateEnabled, setHeartrateEnabled] = useState(true); // 심박수 표시
  const [cadenceEnabled, setCadenceEnabled] = useState(true); // 케이던스 표시

  const [chartColorEnabled, setChartColorEnabled] = useState(false); // 심박수 색상 변경
  const [chartColorSet, setChartColorSet] = useState(colorSetByDefault); // 심박수 색상 변경

  // 시간과 거리 단위 설정
  const [timeInterval, setTimeInterval] = useState(60); // 시간 기반 묶음 단위 (초)
  const [distanceInterval, setDistanceInterval] = useState(1000); // 거리 기반 묶음 단위 (m)

  const [paceBarColor, setPaceBarColor] = useState("#0A4C97cc");
  const [paceBarStroke, setPaceBarStroke] = useState("#ffffff00");
  const [hrPolylineColor, setHrPolylineColor] = useState("#D50000");
  const [cadencePolylineColor, setCadencePolylineColor] = useState("#FFD700"); // 케이던스 색상
  const [altitudeColor, setAltitudeColor] = useState("#999999"); // 연한 회색

  // summary poly line
  const [summaryPolylineEnable, setSummaryPolylineEnable] = useState(true);
  const [summaryPolylineStyle, setSummaryPolylineStyle] = useState({position: 'absolute', top: 50, right: 0});

  return (
    <RunDetailContext.Provider
      value={{
        data,
        aspectRatio,
        layoutEnabled,
        dataText,
        backgroundColor,
        backgroundEnabled,
        textOption,
        textColor,
        fontFamily,
        chartLayoutEnabled,
        chartColorEnabled,
        maxHeartrateEnabled,
        runChartData,
        showChartType,
        heartrateEnabled,
        cadenceEnabled,
        chartColorSet,
        paceBarColor,
        paceBarStroke,
        hrPolylineColor,
        cadencePolylineColor,
        altitudeColor,
        timeInterval,
        distanceInterval,
        summaryPolylineEnable,
        summaryPolylineStyle,

        setData,
        setAspectRatio,
        setLayoutEnabled,
        setDataText,
        setBackgroundColor,
        setBackgroundEnabled,
        setTextOption,
        setTextColor,
        setFontFamily,
        setChartLayoutEnabled,
        setChartColorEnabled,
        setMaxHeartrateEnabled,
        setRunChartData,
        setShowChartType,
        setHeartrateEnabled,
        setCadenceEnabled,
        setChartColorSet,
        setPaceBarColor,
        setPaceBarStroke,
        setHrPolylineColor,
        setCadencePolylineColor,
        setAltitudeColor,
        setTimeInterval,
        setDistanceInterval,
        setSummaryPolylineEnable,
        setSummaryPolylineStyle,
      }}
    >
      {children}
    </RunDetailContext.Provider>
  );
};
