// SwimDetailContext.js
import React, { createContext, useContext, useState } from 'react';
import { colorSetByDefault } from '../components/ChartColorPicker';

const SwimDetailContext = createContext();

export const useSwimDetailContext = () => {
  return useContext(SwimDetailContext);
};

export const SwimChartProvider = ({ children }) => {
  const [data, setData] = useState([]);

  const [aspectRatio, setAspectRatio] = useState(1); // 기본 비율 1:1
  const [layoutEnabled, setLayoutEnabled] = useState(true); // 레이아웃 on/off 상태
  const [dataText, setDataText] = useState(); // 데이터 텍스트
  
  const [backgroundColor, setBackgroundColor] = useState('#333333'); // 기본 배경 색상
  const [backgroundEnabled, setBackgroundEnabled] = useState(false); // 텍스트 불투명 배경 on/off 상태
  
  const [textOption, setTextOption] = useState('option1'); // 선택된 텍스트 옵션
  const [textColor, setTextColor] = useState('#ffffff'); // 텍스트 색상 기본값
  const [fontFamily, setFontFamily] = useState('Open Sans'); // 텍스트 폰트 기본값: Open Sans
  
  // chart
  const [swimChartData, setSwimChartData] = useState([]); // 수영 데이터를 가공한 결과를 저장할 상태
  const [swimChartSimpleData, setSwimChartSimpleData] = useState([]); // 심플 차트

  const [chartLayoutEnabled, setChartLayoutEnabled] = useState(true); // 차트 레이아웃 on/off 상태
  const [simpleChartEnabled, setSimpleChartEnabled] = useState(false); // 심플 차트 변경
  const [chartColorEnabled, setChartColorEnabled] = useState(false); // 심박수 색상 변경
  const [maxHeartrateEnabled, setMaxHeartrateEnabled] = useState(false); // 최대 심박수 표시
  const [avgHeartrateEnabled, setAvgHeartrateEnabled] = useState(true); // 평균 심박수 표시
  const [avgSpeedEnabled, setAvgSpeedEnabled] = useState(true); // 평균 속도 표시
  const [chartColorSet, setChartColorSet] = useState(colorSetByDefault); // 심박수 색상 변경

  return (
    <SwimDetailContext.Provider
      value={{
        data,
        aspectRatio,
        layoutEnabled,
        dataText,
        backgroundColor,
        backgroundEnabled,
        textOption,
        textColor,
        fontFamily,
        chartLayoutEnabled,
        simpleChartEnabled,
        chartColorEnabled,
        maxHeartrateEnabled,
        swimChartData,
        swimChartSimpleData,
        avgHeartrateEnabled,
        avgSpeedEnabled,
        chartColorSet,

        setData,
        setAspectRatio,
        setLayoutEnabled,
        setDataText,
        setBackgroundColor,
        setBackgroundEnabled,
        setTextOption,
        setTextColor,
        setFontFamily,
        setChartLayoutEnabled,
        setSimpleChartEnabled,
        setChartColorEnabled,
        setMaxHeartrateEnabled,
        setSwimChartData,
        setSwimChartSimpleData,
        setAvgHeartrateEnabled,
        setAvgSpeedEnabled,
        setChartColorSet,
      }}
    >
      {children}
    </SwimDetailContext.Provider>
  );
};
