import React from "react";
import { Box, Typography } from "@mui/material";
import { useRunDetailContext } from '../contexts/RunDetailContext';
import { PolylineToSVG } from '../components/Polyline';

const RunImagePreview = ({
  imageRef,
  width,
  height,
  cropData,
  RunChartComponent,
}) => {

  const { 
    data,
    layoutEnabled,
    dataText,
    backgroundColor,
    backgroundEnabled,
    textColor,
    fontFamily,
    chartLayoutEnabled,
    summaryPolylineEnable,
    summaryPolylineStyle,
  } = useRunDetailContext();

  return (
    <Box
      ref={imageRef}
      sx={{
        mt: 2,
        width: `${width}px`,
        height: `${height}px`,
        position: "relative",
        border: "1px solid black",
        backgroundColor: cropData ? "transparent" : backgroundColor,
      }}
    >
      {cropData ? (
        <img
          src={cropData}
          alt="Cropped"
          style={{ width: "100%", height: "100%" }}
        />
      ) : (
        <Box sx={{ width: "100%", height: "100%" }}></Box>
      )}
      {layoutEnabled &&
        dataText.map((textItem, index) => (
          <Box
            key={index}
            sx={{
              position: "absolute",
              top: textItem.top,
              left: textItem.left,
              right: textItem.right,
              transform: textItem.transform || "none",
              color: textColor,
              backgroundColor: backgroundEnabled
                ? textColor === "#ffffff"
                  ? "#00000080"
                  : "#ffffff80"
                : "transparent",
              fontFamily: fontFamily,
              padding: "0px 8px",
              borderRadius: "4px",
            }}
          >
            {textItem.text.map((part, idx) => (
              <Typography
                key={idx}
                component="span"
                sx={{
                  fontSize: part.fontSize || textItem.fontSize,
                  marginRight: part.space ? "4px" : "0",
                  fontFamily: fontFamily,
                }}
              >
                {part.content}
              </Typography>
            ))}
          </Box>
        ))}

        {summaryPolylineEnable && data.summaryPolyline && 
          <Box style={summaryPolylineStyle}>
            <PolylineToSVG
              width={250}
              height={250}
              polyline={data.summaryPolyline}
              backgroundColor={'#ffffff00'}
              strokeColor={textColor}
              strokeWidth={3}
              style={{}}
            />
          </Box>
        }

      {chartLayoutEnabled && (
        <Box
          sx={{
            position: "absolute",
            bottom: "4%",
            left: "4%",
            width: "92%",
          }}
        >
          <RunChartComponent/>
        </Box>
      )}
    </Box>
  );
};

export default RunImagePreview;
