import React, { createContext, useState, useContext, useEffect } from 'react';

const AuthContext = createContext();

export function AuthProvider({ children }) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true); // 로딩 상태 추가

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      console.log('Token found, setting isAuthenticated to true');
      setIsAuthenticated(true);
    } else {
      console.log('No token found, setting isAuthenticated to false');
      setIsAuthenticated(false);
    }
    setIsLoading(false); // 상태 설정 완료 후 로딩 상태 해제
  }, []);

  const login = (token) => {
    localStorage.setItem('token', token); // 로그인 시 토큰을 로컬 스토리지에 저장
    console.log('Login successful, setting isAuthenticated to true');
    setIsAuthenticated(true); // 인증 상태를 true로 설정
  };

  const logout = () => {
    localStorage.removeItem('token'); // 로그아웃 시 토큰 제거
    console.log('Logout successful, setting isAuthenticated to false');
    setIsAuthenticated(false); // 인증 상태를 false로 설정
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout, isLoading }}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  return useContext(AuthContext);
}
