import React from 'react';
import { FormControl, Typography, Box, ToggleButton } from '@mui/material';

const AspectRatioPicker = ({ aspectRatio, handleAspectRatioChange }) => {
  return (
    <FormControl component="fieldset" sx={{ mt: 2 }}>
      <Typography variant="h6">Aspect Ratio</Typography>
      <Box>
        <ToggleButton
          value={1}
          selected={aspectRatio === 1}
          onClick={() => handleAspectRatioChange(1)}
          sx={{ mr: 1 }}
        >
          1:1
        </ToggleButton>
        <ToggleButton
          value={9 / 16}
          selected={aspectRatio === 9 / 16}
          onClick={() => handleAspectRatioChange(9 / 16)}
          sx={{ mr: 1 }}
        >
          9:16
        </ToggleButton>
      </Box>
    </FormControl>
  );
};

export default AspectRatioPicker;