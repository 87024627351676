// DistancePicker.js
import React, { useState } from "react";
import { TextField, ToggleButton, Box, Typography } from "@mui/material";

const DistancePicker = ({
  predefinedDistances,
  distanceInterval,
  handleIntervalDistanceChange,
}) => {

  return (
    <Box>
      <Typography variant="h6">Select Distance:</Typography>
      <Box>
        {predefinedDistances.map((distance, index) => (
          <ToggleButton
            key={index}
            value={distance}
            selected={distanceInterval === distance}
            onClick={() => handleIntervalDistanceChange(distance)}
            sx={{ mr: 1 }}
          >
            {distance >= 1000 ? distance / 1000 + " km" : distance + " m"}
          </ToggleButton>
        ))}
      </Box>
    </Box>
  );
};

export default DistancePicker;
