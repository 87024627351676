import React, { useState, useEffect } from "react";
import useComponentSize from "../utils/useComponentSize";
import { formatTime, calculateTimeFor100m, numberWithCommas, formatLocaleDateWithTimezone } from '../utils/stringUtils.js';
import { useSwimDetailContext } from '../contexts/SwimDetailContext';

const SwimChart = () => {
  const [width, setWidth] = useState(800); // 초기 너비를 설정
  const chartHeight = 120; // 차트의 높이
  const svgHeight = 150; // SVG의 전체 높이
  const topPadding = svgHeight - chartHeight; // 위쪽 여백
  const padding = 10; // 차트 내 여백 설정

  const [componentRef, size] = useComponentSize();

  const [maxHeartrate, setMaxHeartrate] = useState(0);
  const [maxHeartrateXPoint, setMaxHeartrateXPoint] = useState(0);
  const [maxHeartrateYPoint, setMaxHeartrateYPoint] = useState(0);

  const [barChartData, setBarChartData] = useState([]);
  const [polylineData, setPolylineData] = useState("");

  const [xAxisDataType, setXAxisDataType] = useState("time");
  const [maxHeartratePersonal, setMaxHeartratePersonal] = useState(150);

  const [maxDuration, setMaxDuration] = useState(0);
  const [minDuration, setMinDuration] = useState(0);
  const [averageDuration, setAverageDuration] = useState(0);

  // color 
  const [paceBarColor, setPaceBarColor] = useState("#006DB7");
  const [hrPolylineColor, setHrPolylineColor] = useState("#dd1919");

  const { 
    data,
    chartColorEnabled,
    swimChartData,
    swimChartSimpleData,
    simpleChartEnabled,
    maxHeartrateEnabled,
    avgHeartrateEnabled,
    avgSpeedEnabled,
    chartColorSet,
  } = useSwimDetailContext();

  useEffect(() => {
    if (size.width) {
      setWidth(size.width);
    }
  }, [size]);

  useEffect(() => {

    const chartData = simpleChartEnabled ? swimChartSimpleData : swimChartData;
    
    if (chartData.length > 0) {
      const totalDuration = chartData.reduce((acc, entry) => acc + entry.duration, 0);
      const dataLengthWithoutRest = chartData.reduce((acc, entry) => acc + (entry.isRest ? 0 : 1), 0);

      const maxDur = Math.max(...chartData.filter(entry => !entry.isRest).map(entry => entry.duration));
      const minDur = Math.min(...chartData.filter(entry => !entry.isRest).map(entry => entry.duration));
      const avgDur = chartData.filter(entry => !entry.isRest).reduce((acc, entry) => acc + entry.duration, 0) / dataLengthWithoutRest;

      setMaxDuration(maxDur);
      setMinDuration(minDur);
      setAverageDuration(avgDur);

      const maxHR = Math.max(...chartData.map(entry => entry.maxHeartrate));
      setMaxHeartrate(maxHR);

      let xPointWidthAccumulation = 0;
      const barData = [];
      let polyData = "";

      chartData.forEach((entry) => {
        let xPointWidth = 0;

        if (xAxisDataType === "distance") {
          xPointWidth = entry.isRest ? 0 : width / dataLengthWithoutRest;
        } else if (xAxisDataType === "time") {
          xPointWidth = (entry.duration / totalDuration) * width;
        } else {
          xPointWidth = width / chartData.length;
        }

        const barHeight = entry.isRest
          ? 0
          : ((maxDur - entry.duration) / (maxDur - minDur) * (chartHeight * 0.75)) + (chartHeight * 0.25);

        barData.push({
          xPointWidth: xPointWidth,
          xPoint: xPointWidthAccumulation,
          xPointHeight: barHeight,
          averageHeartrate: entry.averageHeartrate,
          distance: entry.distance,
          isRest: entry.isRest,
          duration: entry.duration,
        });

        xPointWidthAccumulation += xPointWidth;

        const polyLineX = xPointWidthAccumulation - xPointWidth / 2;
        const polyLineY =
          chartHeight -
          (entry.averageHeartrate / maxHR) *
            (chartHeight - padding * 2) *
            0.5;
        polyData = polyData + `${polyLineX},${polyLineY} `;

        if (maxHR === entry.maxHeartrate) {
          setMaxHeartrateXPoint(polyLineX);
          setMaxHeartrateYPoint(polyLineY - 10);
        }
      });

      setBarChartData(barData);
      setPolylineData(polyData);
    }
  }, [width, xAxisDataType, simpleChartEnabled]);

  function getHeartrateZoneColor(maxHeartratePercentage) {
    if (maxHeartratePercentage >= 0 && maxHeartratePercentage <= 64) {
      return chartColorSet[0];
    } else if (maxHeartratePercentage >= 65 && maxHeartratePercentage <= 84) {
      return chartColorSet[1];
    } else if (maxHeartratePercentage >= 85 && maxHeartratePercentage <= 91) {
      return chartColorSet[2];
    } else if (maxHeartratePercentage >= 92 && maxHeartratePercentage <= 99) {
      return chartColorSet[3];
    } else if (maxHeartratePercentage >= 100 && maxHeartratePercentage <= 104) {
      return chartColorSet[4];
    } else if (maxHeartratePercentage >= 105) {
      return chartColorSet[5];
    } else {
      return chartColorSet[0];
    }
  }

  return (
    <svg ref={componentRef} width={"100%"} height={svgHeight}>
      <g transform={`translate(0, ${topPadding})`}>
        {barChartData &&
          barChartData.map((entry, index) => {
            return (
              <React.Fragment key={index}>
                <rect
                  x={entry.xPoint}
                  y={chartHeight - entry.xPointHeight}
                  width={entry.xPointWidth}
                  height={entry.xPointHeight}
                  fill={chartColorEnabled ? getHeartrateZoneColor(entry.averageHeartrate / maxHeartratePersonal * 100) : paceBarColor}
                  stroke={chartColorEnabled ? getHeartrateZoneColor(entry.averageHeartrate / maxHeartratePersonal * 100) : paceBarColor}
                  strokeWidth={1}
                />
              </React.Fragment>
            );
          })}

        {polylineData && (
          <polyline
            points={polylineData}
            fill="none"
            stroke={hrPolylineColor}
            strokeWidth={3}
          />
        )}

        {/* 평균 duration 가로선 */}
        {avgSpeedEnabled && (
          <React.Fragment>
            <line
              x1="0"
              y1={chartHeight - ((maxDuration - averageDuration) / (maxDuration - minDuration) * (chartHeight * 0.75)) - (chartHeight * 0.25)}
              x2={width}
              y2={chartHeight - ((maxDuration - averageDuration) / (maxDuration - minDuration) * (chartHeight * 0.75)) - (chartHeight * 0.25)}
              stroke={paceBarColor}
              strokeDasharray="4"
              strokeWidth="1"
            />
            <text
              x={width - 60} // x축 끝에서 약간 왼쪽에 위치
              y={chartHeight - ((maxDuration - averageDuration) / (maxDuration - minDuration) * (chartHeight * 0.75)) - (chartHeight * 0.25) - 10} // 선과 수평을 맞춤
              fontSize="12"
              fill={paceBarColor}
            >
              {`${formatTime(calculateTimeFor100m(data.averageSpeed), true)} /100m`}
            </text>
          </React.Fragment>
        )}

        {/* 평균 heartrate 가로선 */}
        {avgHeartrateEnabled && (
          <React.Fragment>
            <line
              x1="0"
              y1={chartHeight - (data.averageHeartrate / maxHeartrate * chartHeight * 0.5)}
              x2={width}
              y2={chartHeight - (data.averageHeartrate / maxHeartrate * chartHeight * 0.5)}
              stroke={hrPolylineColor}
              strokeDasharray="4"
              strokeWidth="1"
            />
            <text
              x={width - 50} // x축 끝에서 약간 왼쪽에 위치
              y={chartHeight - (data.averageHeartrate / maxHeartrate * chartHeight * 0.5) - 10} // 선과 수평을 맞춤
              fontSize="12"
              fill={hrPolylineColor}
            >
              {`${Math.round(data.averageHeartrate)} bpm`}
            </text>
          </React.Fragment>
        )}

        {maxHeartrateEnabled && maxHeartrateXPoint && maxHeartrateYPoint && (
          <React.Fragment>
            <text x={maxHeartrateXPoint} y={maxHeartrateYPoint} textAnchor="middle">
              <tspan style={{ fontSize: "32px"}}>😡</tspan>
            </text>
          </React.Fragment>
        )}
      </g>
    </svg>
  );
};

export default SwimChart;
