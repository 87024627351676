// 활동 타입에 따라 색상을 반환하는 컴포넌트
const ActivityStyles = (sportType) => {
  // 활동 타입에 따른 배경 및 테두리 색상 반환
  switch (sportType) {
    case 'Run':
    case 'TrailRun':
    case 'VirtualRun':
    case 'Hike':
    case 'Walk':
      return {
        borderColor: '#DC143C',  // 달리기 테두리색
      };
    case 'Ride':
    case 'VirtualRide':
    case 'GravelRide':
    case 'Handcycle':
    case 'MountainBikeRide':
    case 'EBikeRide':
    case 'EMountainBikeRide':
      return {
        borderColor: '#006400',  // 자전거 테두리색
      };
    case 'Swim':
      return {
        borderColor: '#0F52BA',  // 수영 테두리색
      };
    default:
      return {
        borderColor: 'rgba(30, 30, 30, 1)',  // 기본 테두리색
      };
  }
};

export default ActivityStyles;