import React from 'react';
import { useLocation } from 'react-router-dom';
import { Container, Typography } from '@mui/material';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function StravaDone() {
  const query = useQuery();
  const status = query.get('status');

  return (
    <Container maxWidth="sm">
      <Typography variant="h4" gutterBottom>
        Strava Connection Status
      </Typography>
      {
        status === 'success' && (
          <Typography variant="body1">'Strava connection is successful'</Typography>
        )
      }
      {
        status === 'error' && (
          <Typography variant="body1">'Strava connection is error'</Typography>
        )
      }
      {
        status === 'exists' && (
          <Typography variant="body1">'Strava Athlete is already exists'</Typography>
        )
      }
    </Container>
  );
}

export default StravaDone;
