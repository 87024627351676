import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import ProtectedRoute from './components/ProtectedRoute';
import MainLayout from './layouts/MainLayout';
import MinimalLayout from './layouts/MinimalLayout';

// pages
import Signup from './auth/Signup';
import Signin from './auth/Signin';
import Profile from './pages/Profile';
import StravaDone from './pages/StravaDone';
import Activities from './pages/Activities';
import ActivitiyDetail from './pages/ActivityDetail';
import StatMonthActivities from './pages/StatMonthActivities';

const routesConfig = [
  {
    path: "/signup",
    component: Signup,
    layout: MinimalLayout,
    protected: false,
  },
  {
    path: "/signin",
    component: Signin,
    layout: MinimalLayout,
    protected: false,
  },
  {
    path: "/profile",
    component: Profile,
    layout: MainLayout,
    protected: true,
  },
  {
    path: "/connect/strava/done",
    component: StravaDone,
    layout: MainLayout,
    protected: true,
  },
  {
    path: "/activities",
    component: Activities,
    layout: MainLayout,
    protected: true,
  },
  {
    path: "/activity/:id",
    component: ActivitiyDetail,
    layout: MainLayout,
    protected: true,
  },
  {
    path: "/stat/month",
    component: StatMonthActivities,
    layout: MainLayout,
    protected: true,
  },
];

function App() {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          {routesConfig.map((route, index) => {
            const RouteComponent = route.protected ? ProtectedRoute : React.Fragment;

            return (
              <Route
                key={index}
                path={route.path}
                element={
                  <RouteComponent>
                    <route.layout>
                      <route.component />
                    </route.layout>
                  </RouteComponent>
                }
              />
            );
          })}

          <Route path="*" element={<Navigate to="/signin" replace />} />
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;
