import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Typography, CircularProgress, Box, Grid, Paper } from '@mui/material';
import { fetchActivity, fetchActivityStream } from '../services/ApiService';
import { formatTime, calculateTimeFor100m, calculateTimeFor1k } from '../utils/stringUtils.js';

import { SwimChartProvider } from '../contexts/SwimDetailContext';
import { RunChartProvider } from '../contexts/RunDetailContext.js';

import SwimDetail from '../components/SwimDetail.js';
import RunDetail from '../components/RunDetail.js';

import activitiy_bike from '../services/activitiy_1.json'
import activitiy_run from '../services/activitiy_2.json'
import activitiy_swim from '../services/activitiy_3.json'
import activitiy_stream_bike from '../services/activitiy_stream_1.json'
import activitiy_stream_run from '../services/activitiy_stream_2.json'
import activitiy_stream_swim from '../services/activitiy_stream_3.json'

function ActivityDetail() {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  const [activityType, setActivityType] = useState(null);
  const [activity, setActivity] = useState(null);
  const [activityStream, setActivityStream] = useState(null);

  // 수영 데이터를 가공한 결과를 저장할 상태
  const [swimChartData, setSwimChartData] = useState(null);
  const [swimChartSimpleData, setSwimChartSimpleData] = useState(null);
  const [swimData, setSwimData] = useState(null);
  
  // 런닝 데이터를 가공한 결과를 저장할 상태
  const [runChartData, setRunChartData] = useState(null);
  const [runChartSimpleData, setRunChartSimpleData] = useState(null);
  const [runData, setRunData] = useState(null);

  useEffect(() => {
    const loadAllData = async () => {
      try {
        await loadActivity(id);
        setLoading(false); // 로딩 완료
      } catch (err) {
        setError(err.message || 'Failed to fetch activity');
        setLoading(false);
      }
    };
  
    loadAllData();
  }, [id]); // `id`가 변경될 때마다 데이터를 로드
  
  useEffect(() => {
    if (activityStream && activity && activity.sportType.toLowerCase() === 'swim') {
      setActivityType('swim');
      makeSwimChartData();
    }
    else if (activityStream && activity && activity.sportType.toLowerCase() === 'run') {
      setActivityType('run');
      makeRunChartData();
    }
  }, [activity, activityStream]); // `activity`가 업데이트될 때마다 실행

  const loadActivity = async (id) => {
    setLoading(true);
    try {
      if (id === '1') {
        setActivity(activitiy_bike.response)
        setActivityStream(activitiy_stream_bike.response)
      } else if (id === '2') {
        setActivity(activitiy_run.response)
        setActivityStream(activitiy_stream_run.response)
      } else if (id === '3') {
        setActivity(activitiy_swim.response)
        setActivityStream(activitiy_stream_swim.response)
      }

      else {
        const activityData = await fetchActivity(id);
        setActivity(activityData);
        const activityStreamData = await fetchActivityStream(id);
        setActivityStream(activityStreamData);
      }

    } catch (err) {
      setError(err.message || 'Failed to fetch activity');
    } finally {
      setLoading(false);
    }
  };

  // 수영 데이터를 가공하는 함수
  const makeSwimChartData = async () => {
    const distanceArray = activityStream.distance.data;
    const heartrateArray = activityStream.heartrate.data;
    let result = [];

    let duration = 0;
    let sumHeartrate = 0;
    let maxHeartrate = 0;
    let lastDistanse = 0;
    let lastDuration = 0;
    let isRest = false;
    let swimPoolLength = 25;

    // 풀장 길이 구하기
    let multiplesOf25 = [];
    let uniqueMultiplesOf25 = new Set(); // Set을 사용하여 중복 제거

    for (let i = 0; i < distanceArray.length; i++) {
        if (Number.isInteger(distanceArray[i]) && distanceArray[i] % 25 === 0 && distanceArray[i] !== 0) {
            uniqueMultiplesOf25.add(distanceArray[i]); // 중복되지 않게 추가
        }
    }
    multiplesOf25 = Array.from(uniqueMultiplesOf25); // Set을 배열로 변환

    if(25 !== activity.distance / multiplesOf25.length){
      swimPoolLength = 50;
    }

    distanceArray.forEach(distance => {
      if (distance % swimPoolLength === 0 && distance !== 0) {
        if(lastDistanse !== distance){
          let averageHeartrate = Math.round(sumHeartrate / (duration - lastDuration));
          result.push({
            distance: distance,
            averageHeartrate: averageHeartrate,
            maxHeartrate: maxHeartrate,
            duration: duration - lastDuration,
            time: duration,
          })
          sumHeartrate = 0;
          maxHeartrate = 0;
          lastDuration = duration;
          lastDistanse = distance;
        } else {
          // swimPoolLength 단위로 멈춰있고, 같은 거리일 경우 휴식상태로 판단
          isRest = true; // 휴식상태
        }
      }

      if(isRest){
        if(lastDistanse !== distance){
          let averageHeartrate = Math.round(sumHeartrate / (duration - lastDuration));
          result.push({
            distance: distanceArray[duration-1],
            averageHeartrate: averageHeartrate,
            maxHeartrate: maxHeartrate,
            duration: duration - lastDuration,
            time: duration,
            isRest: true,
          })
          sumHeartrate = 0;
          maxHeartrate = 0;
          lastDuration = duration;
          lastDistanse = distance;
          isRest = false;
        }
      }

      lastDistanse = distance;
      maxHeartrate = heartrateArray[duration] > maxHeartrate ? heartrateArray[duration] : maxHeartrate;
      sumHeartrate = sumHeartrate + heartrateArray[duration];
      duration += 1;
    });

    setSwimChartData(result);

    let accumulatedDistance = 0;
    let accumulatedTime = 0;

    const simpleResult = activity.laps.map((lap) => {
      // 거리를 누적시킵니다.
      accumulatedDistance += lap.distance;

      // 시간을 누적시킵니다.
      accumulatedTime += lap.elapsedTime;

      // 새로운 형식의 객체를 반환합니다.
      const transformedLap = {
        distance: accumulatedDistance,
        averageHeartrate: Math.round(lap.averageHeartrate),
        maxHeartrate: Math.round(lap.maxHeartrate),
        duration: lap.elapsedTime,
        time: accumulatedTime,
      };

      // distance가 0.0이면 isRest: true를 추가합니다.
      if (lap.distance === 0.0) {
        transformedLap.isRest = true;
      }

      return transformedLap;
    });

    setSwimChartSimpleData(simpleResult);
    // console.log('simpleResult:', simpleResult);

    setSwimData({
      name: activity.name,
      distance: activity.distance,
      averageHeartrate: activity.averageHeartrate,
      maxHeartrate: activity.maxHeartrate,
      calories: activity.calories,
      movingTime: activity.movingTime,
      elapsedTime: activity.elapsedTime,
      averageSpeed: activity.averageSpeed,
      maxSpeed: activity.maxSpeed,
      swimPoolLength: swimPoolLength,
      startDate: activity.startDate,
      timezone: activity.timezone,
    });
  }

  const makeRunChartData = async () => {
    // console.log('activityStream:', activityStream);
    const distanceArray = activityStream.distance.data;
    const heartrateArray = activityStream.heartrate.data;
    const timeArray = activityStream.time.data;
    const cadenceArray = activityStream.cadence.data;
    const altitudeArray = activityStream.altitude.data;
    const velocitySmoothArray = activityStream.velocitySmooth.data;

    setRunChartData({
      distanceArray: distanceArray,
      heartrateArray: heartrateArray,
      timeArray: timeArray,
      cadenceArray: cadenceArray,
      altitudeArray: altitudeArray,
      velocitySmoothArray: velocitySmoothArray,
    });

    setRunData({
      name: activity.name,
      distance: activity.distance,
      averageHeartrate: activity.averageHeartrate,
      maxHeartrate: activity.maxHeartrate,
      calories: activity.calories,
      movingTime: activity.movingTime,
      elapsedTime: activity.elapsedTime,
      averageSpeed: activity.averageSpeed,
      maxSpeed: activity.maxSpeed,
      averageCadence: activity.averageCadence,
      startDate: activity.startDate,
      timezone: activity.timezone,
      summaryPolyline: activity.map.summaryPolyline,
    });
  }

  if (loading) return <CircularProgress />;
  if (error) return <Typography color="error">{error}</Typography>;

  return (
    <Container>
      <Box mt={4}>
        <Typography variant="h4" gutterBottom>
          Activity Details: {activity.name}
        </Typography>
        {
          activityType === 'swim' && (
            <React.Fragment>
              <Grid container spacing={4} sx={{ mt: 4 }}>
                <Paper elevation={3} style={{ padding: '16px' }}>
                  <Typography variant="h6" gutterBottom>
                    Basic Information
                  </Typography>
                  <Typography variant="body1"><strong>Distance:</strong> {activity.distance} m</Typography>
                  <Typography variant="body1"><strong>Moving Time:</strong> {formatTime(activity.movingTime)}</Typography>
                  <Typography variant="body1"><strong>Elapsed Time:</strong> {formatTime(activity.elapsedTime)}</Typography>
                  <Typography variant="body1"><strong>Average Speed:</strong> {formatTime(calculateTimeFor100m(activity.averageSpeed), true)} /100m</Typography>
                  <Typography variant="body1"><strong>Max Speed:</strong> {formatTime(calculateTimeFor100m(activity.maxSpeed), true)} /100m</Typography>
                  <Typography variant="body1"><strong>Average Heartrate:</strong> {activity.averageHeartrate} bpm</Typography>
                  <Typography variant="body1"><strong>Max Heartrate:</strong> {activity.maxHeartrate} bpm</Typography>
                  <Typography variant="body1"><strong>Calories:</strong> {activity.calories}</Typography>
                </Paper>
              </Grid>
              <Grid container spacing={4} sx={{ mt: 4 }}>
                <SwimChartProvider>
                  <SwimDetail swimData={swimData} swimChartData={swimChartData} swimChartSimpleData={swimChartSimpleData}/>
                </SwimChartProvider>
              </Grid>
            </React.Fragment>
          )
        }
        {
          activityType === 'run' && (
            <React.Fragment>
              <Grid container spacing={4} sx={{ mt: 4 }}>
                <Paper elevation={3} style={{ padding: '16px' }}>
                  <Typography variant="h6" gutterBottom>
                    Basic Information
                  </Typography>
                  <Typography variant="body1"><strong>Distance:</strong> {(activity.distance / 1000).toFixed(2)} km</Typography>
                  <Typography variant="body1"><strong>Moving Time:</strong> {formatTime(activity.movingTime)}</Typography>
                  <Typography variant="body1"><strong>Elapsed Time:</strong> {formatTime(activity.elapsedTime)}</Typography>
                  <Typography variant="body1"><strong>Average Speed:</strong> {formatTime(calculateTimeFor1k(activity.averageSpeed), true)} /1km</Typography>
                  <Typography variant="body1"><strong>Max Speed:</strong> {formatTime(calculateTimeFor1k(activity.maxSpeed), true)} /1km</Typography>
                  <Typography variant="body1"><strong>Average Heartrate:</strong> {activity.averageHeartrate} bpm</Typography>
                  <Typography variant="body1"><strong>Max Heartrate:</strong> {activity.maxHeartrate} bpm</Typography>
                  <Typography variant="body1"><strong>Average Cadence:</strong> {activity.averageCadence} rpm</Typography>
                  <Typography variant="body1"><strong>Calories:</strong> {activity.calories}</Typography>
                </Paper>
              </Grid>
              <Grid container spacing={4} sx={{ mt: 4 }}>
                <RunChartProvider>
                  <RunDetail runData={runData} runChartData={runChartData} />

                  {/* <RunChart runChartData={runChartData}/> */}
                </RunChartProvider>
              </Grid>
            </React.Fragment>
          )
        }
      </Box>
    </Container>
  );
}

export default ActivityDetail;