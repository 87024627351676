import React from 'react';
import { FaRunning, FaBiking, FaSwimmer } from 'react-icons/fa';  // 필요한 아이콘들

// 활동 타입에 따라 아이콘을 반환하는 컴포넌트
const ActivityIcon = ({ sportType, iconSize = 20, iconColor = '#ddd' }) => {
  // 활동 타입에 따른 아이콘 반환
  switch (sportType) {
    case 'Run':
    case 'TrailRun':
    case 'VirtualRun':
    case 'Hike':
    case 'Walk':
      return <FaRunning style={{ color: iconColor }} size={iconSize} />;  // 달리기 아이콘
    case 'Ride':
    case 'VirtualRide':
    case 'GravelRide':
    case 'Handcycle':
    case 'MountainBikeRide':
    case 'EBikeRide':
    case 'EMountainBikeRide':
      return <FaBiking style={{ color: iconColor }} size={iconSize} />;  // 자전거 아이콘
    case 'Swim':
      return <FaSwimmer style={{ color: iconColor }} size={iconSize} />;  // 수영 아이콘
    default:
      return null;  // 기본 아이콘 없음
  }
};

export default ActivityIcon;