import React, { useState } from 'react';
import { FormControl, Typography, Box } from '@mui/material';

// 색상 코드 배열
const colorCodes = [
  "#FFFFFF", "#F5F5F5", "#DCDCDC", "#C0C0C0", "#A9A9A9", "#808080", "#696969", "#000000",
  "#FF0000", "#E32636", "#DC143C", "#B22222", "#FF6347", "#FF4500", "#FF7F50", "#FFA500", "#FF8243", "#FF8C00",
  "#FFFF00", "#FFD700", "#FADA5E",
  "#32CD32", "#00FA9A", "#009B77", "#006400", "#008000", "#228B22", "#2E8B57",
  "#00BFFF", "#1E90FF", "#0F52BA", "#1034A6", "#4682B4", "#007BA7", "#0F4D92", "#0000FF",
  "#27408B", "#000080", "#483D8B", "#4B0082",
  "#800080", "#8A2BE2", "#9932CC", "#9370DB", "#9400D3", "#B57EDC", "#BA55D3", "#8B008B"
];

// 컴포넌트
const AllColorPicker = ({ subTitle, onColorSelect }) => {
  const [selectedColor, setSelectedColor] = useState(null); // 선택한 색상 상태 관리
  const handleColorClick = (color) => {
    setSelectedColor(color); // 클릭한 색상을 상태로 설정
    onColorSelect(color); // 부모 컴포넌트로 선택한 색상 전달
  };

  return (
    <FormControl component="fieldset" sx={{ mt: 2 }}>
      {subTitle && <Typography variant="subtitle1">{subTitle}</Typography>}
      <Box sx={{ 
        display: 'flex', flexWrap: 'wrap', gap: 0.5, 
        maxHeight: { xs: 100, sm: 300 },
        overflowY: 'auto',
      }}>
        {colorCodes.map((color, index) => (
          <Box
            key={index}
            onClick={() => handleColorClick(color)} // 클릭 시 색상 선택
            sx={{
              width: 40,
              height: 20,
              backgroundColor: color,
              border: selectedColor === color ? (selectedColor === '#000000' ? '3px solid #aaa' : '3px solid #000') : '2px solid transparent', // 선택한 색상에 테두리 추가
              cursor: 'pointer'
            }}
          />
        ))}
      </Box>
    </FormControl>
  );
};

export default AllColorPicker;
