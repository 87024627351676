// TimePicker.js
import React, { useState } from 'react';
import { TextField, Button, Box, Typography, ToggleButton } from '@mui/material';

const TimePicker = ({ predefinedTimes, timeInterval, handleIntervalTimeChange }) => {

  return (
    <Box>
      <Typography variant="h6">Choose Interval:</Typography>
      <Box>
        {predefinedTimes.map((time, index) => (
          <ToggleButton
            key={index}
            value={time}
            selected={timeInterval === time * 60}
            onClick={() => handleIntervalTimeChange(time * 60)}
            sx={{ mr: 1 }}
          >
            {time} min
          </ToggleButton>
        ))}
      </Box>
    </Box>
  );
};

export default TimePicker;