import React from 'react';
import { Box, Button, Modal } from '@mui/material';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';

const CropImageModal = ({ image, modalOpen, setModalOpen, cropperRef, handleCrop, aspectRatio }) => {
  return (
    <Modal
      open={modalOpen}
      onClose={() => setModalOpen(false)}
    >
      <Box sx={{ width: '80%', maxWidth: 600, p: 4, margin: 'auto', bgcolor: 'background.paper', boxShadow: 24 }}>
        {image && (
          <>
            <Cropper
              src={image}
              style={{ height: 400, width: '100%' }}
              aspectRatio={aspectRatio}
              guides={true}
              ref={cropperRef}
              viewMode={1} // Crop 영역이 이미지 밖으로 나가지 않도록 제한
              minCropBoxWidth={10}
              minCropBoxHeight={10}
              background={false}
              responsive={true}
              autoCropArea={1}
            />
            <Button variant="contained" color="primary" onClick={handleCrop} sx={{ mt: 2 }}>
              Crop Image
            </Button>
          </>
        )}
      </Box>
    </Modal>
  );
};

export default CropImageModal;