import React from 'react';
import { Box, ToggleButton, Typography } from '@mui/material';

const TextLayoutPicker = ({ textOptions, textOption, handleTextOptionChange }) => {
  return (
    <>
      <Typography variant="h6">Text Layout</Typography>
      <Box>
        {textOptions.map((option, index) => (
          <ToggleButton
            key={index}
            value={option.value}
            selected={textOption === option.value}
            onClick={() => handleTextOptionChange(option.value)}
            sx={{ mr: 1 }}
          >
            {option.label}
          </ToggleButton>
        ))}
      </Box>
    </>
  );
};

export default TextLayoutPicker;