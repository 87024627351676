import React, { useState, useEffect } from "react";
import useComponentSize from "../utils/useComponentSize.js";

// contexts
import { useRunDetailContext } from '../contexts/RunDetailContext.js';

const RunChart = () => {
  const [width, setWidth] = useState(800); // 초기 너비 설정
  const chartHeight = 120; // 차트의 높이
  const svgHeight = 150; // 전체 SVG의 높이
  const topPadding = svgHeight - chartHeight; // 상단 여백

  const [componentRef, size] = useComponentSize(); // 컴포넌트 크기 확인

  const [timeBarChartData, setTimeBarChartData] = useState([]); // 시간 기반 차트 데이터
  const [distanceBarChartData, setDistanceBarChartData] = useState([]); // 거리 기반 차트 데이터
  const [timePolylineData, setTimePolylineData] = useState(""); // 시간 기반 심박수 데이터
  const [distancePolylineData, setDistancePolylineData] = useState(""); // 거리 기반 심박수 데이터
  const [timeAltitudePolygonData, setTimeAltitudePolygonData] = useState(""); // 시간 기준 고도 폴리곤
  const [distanceAltitudePolygonData, setDistanceAltitudePolygonData] = useState(""); // 거리 기준 고도 폴리곤
  const [timeCadencePolylineData, setTimeCadencePolylineData] = useState(""); // 시간 기준 케이던스 폴리라인
  const [distanceCadencePolylineData, setDistanceCadencePolylineData] = useState(""); // 거리 기준 케이던스 폴리라인

  const barGap = 2; // 막대 간격
  const polylineMinHrPoint = 0; // 심박수 최소값
  const polylineMaxHrPoint = 200; // 심박수 최대값
  const polylineMinCadencePoint = 0; // 케이던스 최소값
  const polylineMaxCadencePoint = 200; // 케이던스 최대값 (예시값, 필요시 조정)

  const { 
    runChartData,
    showChartType,
    timeInterval,
    distanceInterval,
    paceBarColor,
    paceBarStroke,
    hrPolylineColor,
    cadencePolylineColor,
    altitudeColor,
    heartrateEnabled,
    cadenceEnabled,
    chartColorEnabled,
    chartColorSet,
  } = useRunDetailContext(); // 외부에서 데이터 가져오기

  // 크기 변경 시 차트 너비 업데이트
  useEffect(() => {
    if (size.width) {
      setWidth(size.width);
    }
  }, [size]);

  useEffect(() => {
    const { timeArray, distanceArray, heartrateArray, altitudeArray, cadenceArray } = runChartData

    // 데이터가 없을 경우 오류 처리
    if (!timeArray || !distanceArray || !heartrateArray || !altitudeArray || !cadenceArray) {
      return;
    }

    // 고도 데이터의 최대값과 최소값 계산
    const altitudeMin = Math.min(...altitudeArray);
    const altitudeMax = Math.max(...altitudeArray);

    if(showChartType === 'time') {
      // 시간을 기준으로 데이터를 묶는 함수
      const { groupedTime, groupedDistance, groupedDurations } = groupDataByInterval(timeArray, distanceArray, timeInterval);

      // 각 구간 속도 계산
      const timeSpeeds = groupedDistance.map((dist, i) => dist / groupedDurations[i]);

      // 시간 기반 차트 데이터 생성
      setTimeBarChartData(generateChartData(groupedTime, groupedDurations, timeSpeeds, width, barGap, paceBarColor, paceBarStroke, chartColorEnabled, chartColorSet));
  
      // 시간 기반 고도 데이터 생성
      setTimeAltitudePolygonData(generateAltitudePolygon(altitudeArray, width, chartHeight, altitudeMin, altitudeMax));
  
      // 시간 기반 심박수 데이터 생성
      setTimePolylineData(generatePolyline(heartrateArray, width, chartHeight, polylineMinHrPoint, polylineMaxHrPoint));
  
      // 시간 기반 케이던스 데이터 생성
      setTimeCadencePolylineData(generatePolyline(cadenceArray, width, chartHeight, polylineMinCadencePoint, polylineMaxCadencePoint));
    }
    else if(showChartType === 'distance') {

      // 거리를 기준으로 데이터를 묶는 함수
      const { groupedTime: distGroupedTime, groupedDistance: distGroupedDistance } = groupDataByDistanceInterval(timeArray, distanceArray, distanceInterval);
  
      // 거리 기반 속도 계산
      const distanceSpeeds = calculateSpeeds(distGroupedTime, distGroupedDistance);
  
      // 거리 기반 차트 데이터 생성
      setDistanceBarChartData(generateChartData(distGroupedTime, distGroupedDistance, distanceSpeeds, width, barGap, paceBarColor, paceBarStroke, chartColorEnabled, chartColorSet));
  
      // 거리 기반 고도 데이터 생성
      setDistanceAltitudePolygonData(generateAltitudePolygon(altitudeArray, width, chartHeight, altitudeMin, altitudeMax));
  
      // 거리 기반 심박수 데이터 생성
      setDistancePolylineData(generatePolyline(heartrateArray, width, chartHeight, polylineMinHrPoint, polylineMaxHrPoint));
  
      // 거리 기반 케이던스 데이터 생성
      setDistanceCadencePolylineData(generatePolyline(cadenceArray, width, chartHeight, polylineMinCadencePoint, polylineMaxCadencePoint));
    }

  }, [width, paceBarColor, showChartType, timeInterval, distanceInterval, chartColorEnabled, chartColorSet, runChartData]);

  // 시간을 기준으로 데이터를 묶는 함수
  const groupDataByInterval = (timeData, distanceData, interval) => {
    const groupedTime = [];
    const groupedDistance = [];
    const groupedDurations = [];

    let currentIntervalStart = 0;
    let lastDistance = 0;
    let count = 0;
    let lastTime = timeData[0];

    for (let i = 0; i < timeData.length; i++) {
      const currentTime = timeData[i];
      const currentDistance = distanceData[i];

      if (currentTime >= currentIntervalStart + interval) {
        const distanceChange = currentDistance - lastDistance;
        groupedTime.push(currentIntervalStart);
        groupedDistance.push(distanceChange);
        groupedDurations.push(currentIntervalStart + interval - lastTime);

        lastTime = currentIntervalStart + interval;
        currentIntervalStart += interval;
        lastDistance = currentDistance;
        count = 0;
      }

      count += 1;
    }

    if (count > 0 && currentIntervalStart <= timeData[timeData.length - 1]) {
      const distanceChange = distanceData[distanceData.length - 1] - lastDistance;
      groupedTime.push(currentIntervalStart);
      groupedDistance.push(distanceChange);
      groupedDurations.push(timeData[timeData.length - 1] - lastTime);
    }

    return { groupedTime, groupedDistance, groupedDurations };
  };

  // 거리를 기준으로 데이터를 묶는 함수
  const groupDataByDistanceInterval = (timeData, distanceData, interval) => {
    const groupedTime = [];
    const groupedDistance = [];

    let currentIntervalStart = 0;
    let count = 0;

    for (let i = 0; i < distanceData.length; i++) {
      const currentDistance = distanceData[i];
      const currentTime = timeData[i];

      if (currentDistance >= currentIntervalStart + interval) {
        groupedTime.push(currentTime);
        const distanceChange = Math.min(interval, currentDistance - currentIntervalStart);
        groupedDistance.push(distanceChange);
        currentIntervalStart += interval;
        count = 0;
      }

      count += 1;
    }

    if (count > 0) {
      groupedTime.push(timeData[timeData.length - 1]);
      const lastDistanceChange = distanceData[distanceData.length - 1] - currentIntervalStart;
      groupedDistance.push(lastDistanceChange);
    }

    return { groupedTime, groupedDistance };
  };

  // 차트 데이터를 생성하는 함수
  const generateChartData = (groupedTime, groupedDurations, speeds, width, barGap, color, stroke, chartColorEnabled, chartColorSet) => {
    const totalDuration = groupedDurations.reduce((acc, duration) => acc + duration, 0);

    return groupedTime.map((time, index) => {
      const xPointWidth = (groupedDurations[index] / totalDuration) * (width - (groupedTime.length - 1) * barGap); 
      const xPoint = groupedTime.slice(0, index).reduce((acc, _, i) => acc + (groupedDurations[i] / totalDuration) * (width - (groupedTime.length - 1) * barGap) + barGap, 0);

      return {
        xPoint: xPoint,
        xPointWidth: xPointWidth,
        speed: speeds[index],
        color: chartColorEnabled ? chartColorSet[0] + 'e5' : color,
        stroke: stroke,
      };
    });
  };

  // 고도 폴리곤 데이터를 생성하는 함수
  const generateAltitudePolygon = (altitudeData, width, chartHeight, altitudeMin, altitudeMax) => {
    return altitudeData.map((alt, index) => {
      const polyX = (index / (altitudeData.length - 1)) * width;
      const normalizedAltitude = (alt - altitudeMin) / (altitudeMax - altitudeMin);
      const polyY = chartHeight - (normalizedAltitude * chartHeight);
      return `${polyX},${polyY}`;
    }).join(" ") + ` ${width},${chartHeight} 0,${chartHeight}`;
  };

  // 심박수 폴리라인 데이터를 생성하는 함수
  const generatePolyline = (data, width, chartHeight, min, max) => {
    return data.map((value, index) => {
      const polyX = (index / (data.length - 1)) * width;
      const normalizedValue = (value - min) / (max - min);
      const polyY = chartHeight - (normalizedValue * chartHeight);
      return `${polyX},${polyY}`;
    }).join(" ");
  };

  // 속도를 계산하는 함수
  const calculateSpeeds = (groupedTime, groupedDistance) => {
    return groupedTime.map((currentTime, index) => {
      let timeChange = 0;
      if (index === 0) {
        timeChange = currentTime;
      } else {
        timeChange = currentTime - groupedTime[index - 1];
      }

      return timeChange > 0 ? groupedDistance[index] / timeChange : 0;
    });
  };

  return (
    <React.Fragment>
      {/* 시간 기준 차트 */}
      {showChartType === 'time' && (
        <svg ref={componentRef} width={"100%"} height={svgHeight}>
          <g transform={`translate(0, ${topPadding})`}>
            <polygon points={timeAltitudePolygonData} fill={altitudeColor} />
            {timeBarChartData.map((entry, index) => (
              <React.Fragment key={index}>
                <rect
                  x={entry.xPoint}
                  y={chartHeight - (entry.speed / Math.max(...timeBarChartData.map(data => data.speed)) * chartHeight)}
                  width={entry.xPointWidth}
                  height={(entry.speed / Math.max(...timeBarChartData.map(data => data.speed)) * chartHeight)}
                  fill={entry.color}
                  stroke={entry.stroke}
                  strokeWidth={0}
                />
              </React.Fragment>
            ))}
            {/* 시간 기반 심박수 polyline */}
            {
              heartrateEnabled &&
              <polyline points={timePolylineData} fill="none" stroke={chartColorEnabled ? chartColorSet[4]: hrPolylineColor} strokeWidth={2} />
            }
            {/* 시간 기반 케이던스 polyline */}
            {
              cadenceEnabled &&
              <polyline points={timeCadencePolylineData} fill="none" stroke={chartColorEnabled ? chartColorSet[2] : cadencePolylineColor} strokeWidth={2} />
            }
          </g>
        </svg>
      )}

      {/* 거리 기준 차트 */}
      {showChartType === 'distance' && (
        <svg ref={componentRef} width={"100%"} height={svgHeight}>
          <g transform={`translate(0, ${topPadding})`}>
            <polygon points={distanceAltitudePolygonData} fill={altitudeColor} />
            {distanceBarChartData.map((entry, index) => (
              <React.Fragment key={index}>
                <rect
                  x={entry.xPoint}
                  y={chartHeight - (entry.speed / Math.max(...distanceBarChartData.map(data => data.speed)) * chartHeight)}
                  width={entry.xPointWidth}
                  height={(entry.speed / Math.max(...distanceBarChartData.map(data => data.speed)) * chartHeight)}
                  fill={entry.color}
                  stroke={entry.stroke}
                  strokeWidth={0}
                />
              </React.Fragment>
            ))}
            {/* 거리 기준 심박수 polyline */}
            {heartrateEnabled && 
              <polyline points={distancePolylineData} fill="none" stroke={chartColorEnabled ? chartColorSet[4]: hrPolylineColor} strokeWidth={2} />
            }
            {/* 거리 기준 케이던스 polyline */}
            {cadenceEnabled && 
              <polyline points={distanceCadencePolylineData} fill="none" stroke={chartColorEnabled ? chartColorSet[2] : cadencePolylineColor} strokeWidth={2} />
            }
          </g>
        </svg>
      )}
    </React.Fragment>
  );
};

export default RunChart;
