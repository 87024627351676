import React, { useState, useRef, useEffect } from "react";
import {
  Checkbox,
  Container,
  Button,
  Typography,
  Box,
  FormControl,
  FormControlLabel,
  ToggleButton,
  TextField,
} from "@mui/material";
import { toPng } from "html-to-image";
import {
  formatTime,
  calculateTimeFor100m,
  numberWithCommas,
  formatLocaleDateWithTimezone,
  calculateTimeFor1k,
} from "../utils/stringUtils.js";

// contexts
import { useRunDetailContext } from "../contexts/RunDetailContext.js";

// components
import BackgroundColorPicker from "./BackgroundColorPicker.js";
import TextColorPicker from "./TextColorPicker.js";
import FontPicker from "./FontPicker.js";
import AspectRatioPicker from "./AspectRatioPicker.js";
import CropImageModal from "./CropImageModal.js";
import RunImagePreview from "./RunImagePreview.js";
import RunChart from "./RunChart.js";
import TextLayoutPicker from "./TextLayoutPicker.js";
import ChartColorPicker from "./ChartColorPicker.js";
import TimePicker from "./TimePicker.js";
import DistancePicker from "./DistancePicker.js";

// icons
import { FaRunning } from "react-icons/fa";
import { FaFire } from "react-icons/fa6";
import { FaStopwatch } from "react-icons/fa";
import { FaHeartbeat } from "react-icons/fa";
import { FaClock } from "react-icons/fa";
import { FaRuler } from "react-icons/fa";

const RunDetail = ({ runData, runChartData, runChartSimpleData }) => {
  const [image, setImage] = useState(null);
  const [cropData, setCropData] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  //ref
  const cropperRef = useRef(null);
  const imageRef = useRef(null); // 이미지와 레이아웃 전체를 감싸는 요소의 참조

  const backgroundColorSet = ["#ffffff", "#333333", "#000000"];

  const fontColorSet = ["#ffffff", "#000000"];

  const textOptions = [
    { value: "option1", label: "Option 1" },
    { value: "option2", label: "Option 2" },
    { value: "option3", label: "Option 3" },
  ];

  const chartType = [
    { value: "time", label: "Time" },
    { value: "distance", label: "Distance" },
  ];

  const predefinedTimes = [1, 2, 3, 5, 10, 15, 20, 30, 60];

  const predefinedDistances = [100, 200, 400, 500, 1000, 5000];

  const {
    aspectRatio,
    layoutEnabled,
    dataText,
    backgroundColor,
    backgroundEnabled,
    textOption,
    textColor,
    fontFamily,
    chartLayoutEnabled,
    chartColorEnabled,
    chartColorSet,
    heartrateEnabled,
    cadenceEnabled,
    showChartType,
    timeInterval,
    distanceInterval,

    setData,
    setAspectRatio,
    setLayoutEnabled,
    setDataText,
    setBackgroundColor,
    setBackgroundEnabled,
    setTextOption,
    setTextColor,
    setFontFamily,
    setChartLayoutEnabled,
    setRunChartData,
    setSummaryPolylineEnable,
    setSummaryPolylineStyle,
    setChartColorEnabled,
    setChartColorSet,
    setHeartrateEnabled,
    setCadenceEnabled,
    setShowChartType,
    setTimeInterval,
    setDistanceInterval,
  } = useRunDetailContext();

  const onChange = (e) => {
    e.preventDefault();
    const files = e.target.files;
    if (files && files.length > 0) {
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result);
        setModalOpen(true); // 이미지 업로드 시 모달 열기
      };
      reader.readAsDataURL(files[0]);
    }
  };

  const handleCrop = () => {
    if (cropperRef.current) {
      const croppedCanvas = cropperRef.current.cropper.getCroppedCanvas();
      setCropData(croppedCanvas.toDataURL());
      setModalOpen(false); // 크롭 후 모달 닫기
    }
  };

  const handleAspectRatioChange = (value) => {
    setAspectRatio(value);
  };

  const handleTextOptionChange = (value) => {
    setTextOption(value);
  };

  const handleTextColorChange = (value) => {
    setTextColor(value);
  };

  const handleFontFamilyChange = (value) => {
    setFontFamily(value);
  };

  const handleBackgroundColorChange = (value) => {
    setBackgroundColor(value);
  };

  const handleChartColorSetChange = (newSet) => {
    setChartColorSet(newSet);
  };

  const handleShowChartTypeChange = (value) => {
    setShowChartType(value);
  };

  const handleIntervalTimeChange = (value) => {
    setTimeInterval(value);
  };

  const handleIntervalDistanceChange = (value) => {
    setDistanceInterval(value);
  };

  const downloadImage = () => {
    if (imageRef.current) {
      toPng(imageRef.current)
        .then((dataUrl) => {
          const link = document.createElement("a");
          link.href = dataUrl;
          link.download = "image-with-layout.png";
          link.click();
        })
        .catch((err) => {
          console.error("Error downloading the image:", err);
        });
    }
  };

  const createTextLayout = () => {
    let texts = [];
    // console.log(runData);

    const distance = numberWithCommas((runData.distance / 1000), 2);
    const averageHeartrate = Math.round(runData.averageHeartrate);
    const calories = numberWithCommas(runData.calories);
    const movingTime = formatTime(runData.movingTime);
    const elapsedTime = formatTime(runData.elapsedTime);
    const averageSpeed = formatTime(
      calculateTimeFor1k(runData.averageSpeed),
      true
    );
    const maxSpeed = formatTime(calculateTimeFor1k(runData.maxSpeed), true);
    const startDate = formatLocaleDateWithTimezone(
      runData.startDate,
      runData.timezone
    );

    if (runData) {
      switch (textOption) {
        case "option1":
          //////////////////////////////////////////////// option1
          setSummaryPolylineEnable(true);
          setSummaryPolylineStyle({ position: "absolute", top: 50, right: 0 });

          const leftPosition1 = "5%";
          texts.push({
            text: [
              {
                content: (
                  <FaRunning style={{ fontSize: "18px", marginRight: 8 }} />
                ),
                fontSize: "",
              },
              { content: `${runData.name}`, fontSize: "24px" },
            ],
            top: "calc(5%)",
            left: leftPosition1,
            fontSize: "20px",
          });
          texts.push({
            text: [{ content: ` ${startDate}`, fontSize: "14px" }],
            top: "calc(5% + 40px)",
            left: leftPosition1,
            fontSize: "20px",
          });
          texts.push({
            text: [
              {
                content: (
                  <FaRuler style={{ fontSize: "18px", marginRight: 8 }} />
                ),
                fontSize: "",
              },
              // { content: `Distance: `, fontSize: '18px' },
              { content: `${distance}`, fontSize: "24px" },
              { content: ` km`, fontSize: "16px" },
            ],
            top: "calc(5% + 80px)",
            left: leftPosition1,
            fontSize: "20px",
          });
          texts.push({
            text: [
              {
                content: (
                  <FaClock style={{ fontSize: "18px", marginRight: 8 }} />
                ),
                fontSize: "",
              },
              // { content: `Time: `, fontSize: '18px' },
              { content: `${movingTime}`, fontSize: "24px" },
            ],
            top: "calc(5% + 120px)",
            left: leftPosition1,
            fontSize: "20px",
          });
          texts.push({
            text: [
              {
                content: (
                  <FaHeartbeat style={{ fontSize: "18px", marginRight: 8 }} />
                ),
                fontSize: "",
              },
              // { content: `HR: `, fontSize: '18px' },
              { content: `${averageHeartrate}`, fontSize: "24px" },
              { content: ` bpm `, fontSize: "18px" },
            ],
            top: "calc(5% + 160px)",
            left: leftPosition1,
            fontSize: "20px",
          });
          texts.push({
            text: [
              {
                content: (
                  <FaStopwatch style={{ fontSize: "18px", marginRight: 8 }} />
                ),
                fontSize: "",
              },
              // { content: `pace: `, fontSize: '18px' },
              { content: `${averageSpeed}`, fontSize: "24px" },
              { content: ` /1km`, fontSize: "16px" },
            ],
            top: "calc(5% + 200px)",
            left: leftPosition1,
            fontSize: "20px",
          });
          texts.push({
            text: [
              {
                content: (
                  <FaFire style={{ fontSize: "18px", marginRight: 8 }} />
                ),
                fontSize: "",
              },
              // { content: `Calories: `, fontSize: '20px' },
              { content: `${calories}`, fontSize: "26px" },
              { content: ` kcal`, fontSize: "16px" },
            ],
            top: "calc(5% + 240px)",
            left: leftPosition1,
            fontSize: "20px",
          });
          break;
        case "option2":
          //////////////////////////////////////////////// option2
          setSummaryPolylineEnable(false);

          const leftPosition2 = "50%";
          const transform2 = "translate(-50%, -50%)";
          texts.push({
            text: [
              {
                content: (
                  <FaRunning style={{ fontSize: "18px", marginRight: 8 }} />
                ),
                fontSize: "",
              },
              { content: `${runData.name}`, fontSize: "24px" },
            ],
            top: "calc(5%)",
            left: leftPosition2,
            transform: transform2,
            fontSize: "20px",
          });
          texts.push({
            text: [{ content: ` ${startDate}`, fontSize: "14px" }],
            top: "calc(5% + 40px)",
            left: leftPosition2,
            transform: transform2,
            fontSize: "20px",
          });
          texts.push({
            text: [
              {
                content: (
                  <FaRuler style={{ fontSize: "18px", marginRight: 8 }} />
                ),
                fontSize: "",
              },
              // { content: `Distance: `, fontSize: '18px' },
              { content: `${distance}`, fontSize: "24px" },
              { content: ` km`, fontSize: "16px" },
            ],
            top: "calc(5% + 80px)",
            left: leftPosition2,
            transform: transform2,
            fontSize: "20px",
          });
          texts.push({
            text: [
              {
                content: (
                  <FaClock style={{ fontSize: "18px", marginRight: 8 }} />
                ),
                fontSize: "",
              },
              // { content: `Time: `, fontSize: '18px' },
              { content: `${movingTime}`, fontSize: "24px" },
            ],
            top: "calc(5% + 120px)",
            left: leftPosition2,
            transform: transform2,
            fontSize: "20px",
          });
          texts.push({
            text: [
              {
                content: (
                  <FaHeartbeat style={{ fontSize: "18px", marginRight: 8 }} />
                ),
                fontSize: "",
              },
              // { content: `HR: `, fontSize: '18px' },
              { content: `${averageHeartrate}`, fontSize: "24px" },
              { content: ` bpm `, fontSize: "18px" },
            ],
            top: "calc(5% + 160px)",
            left: leftPosition2,
            transform: transform2,
            fontSize: "20px",
          });
          texts.push({
            text: [
              {
                content: (
                  <FaStopwatch style={{ fontSize: "18px", marginRight: 8 }} />
                ),
                fontSize: "",
              },
              // { content: `pace: `, fontSize: '18px' },
              { content: `${averageSpeed}`, fontSize: "24px" },
              { content: ` /1km`, fontSize: "16px" },
            ],
            top: "calc(5% + 200px)",
            left: leftPosition2,
            transform: transform2,
            fontSize: "20px",
          });
          texts.push({
            text: [
              {
                content: (
                  <FaFire style={{ fontSize: "18px", marginRight: 8 }} />
                ),
                fontSize: "",
              },
              // { content: `Calories: `, fontSize: '20px' },
              { content: `${calories}`, fontSize: "26px" },
              { content: ` kcal`, fontSize: "16px" },
            ],
            top: "calc(5% + 240px)",
            left: leftPosition2,
            transform: transform2,
            fontSize: "20px",
          });

          break;
        case "option3":
          //////////////////////////////////////////////// option1
          setSummaryPolylineEnable(true);
          setSummaryPolylineStyle({ position: "absolute", top: 50, left: 0 });

          const rightPosition3 = "5%";
          texts.push({
            text: [
              {
                content: (
                  <FaRunning style={{ fontSize: "18px", marginRight: 8 }} />
                ),
                fontSize: "",
              },
              { content: `${runData.name}`, fontSize: "24px" },
            ],
            top: "calc(5%)",
            right: rightPosition3,
            fontSize: "20px",
          });
          texts.push({
            text: [{ content: ` ${startDate}`, fontSize: "14px" }],
            top: "calc(5% + 40px)",
            right: rightPosition3,
            fontSize: "20px",
          });
          texts.push({
            text: [
              {
                content: (
                  <FaRuler style={{ fontSize: "18px", marginRight: 8 }} />
                ),
                fontSize: "",
              },
              // { content: `Distance: `, fontSize: '18px' },
              { content: `${distance}`, fontSize: "24px" },
              { content: ` km`, fontSize: "16px" },
            ],
            top: "calc(5% + 80px)",
            right: rightPosition3,
            fontSize: "20px",
          });
          texts.push({
            text: [
              {
                content: (
                  <FaClock style={{ fontSize: "18px", marginRight: 8 }} />
                ),
                fontSize: "",
              },
              // { content: `Time: `, fontSize: '18px' },
              { content: `${movingTime}`, fontSize: "24px" },
            ],
            top: "calc(5% + 120px)",
            right: rightPosition3,
            fontSize: "20px",
          });
          texts.push({
            text: [
              {
                content: (
                  <FaHeartbeat style={{ fontSize: "18px", marginRight: 8 }} />
                ),
                fontSize: "",
              },
              // { content: `HR: `, fontSize: '18px' },
              { content: `${averageHeartrate}`, fontSize: "24px" },
              { content: ` bpm `, fontSize: "18px" },
            ],
            top: "calc(5% + 160px)",
            right: rightPosition3,
            fontSize: "20px",
          });
          texts.push({
            text: [
              {
                content: (
                  <FaStopwatch style={{ fontSize: "18px", marginRight: 8 }} />
                ),
                fontSize: "",
              },
              // { content: `pace: `, fontSize: '18px' },
              { content: `${averageSpeed}`, fontSize: "24px" },
              { content: ` /1km`, fontSize: "16px" },
            ],
            top: "calc(5% + 200px)",
            right: rightPosition3,
            fontSize: "20px",
          });
          texts.push({
            text: [
              {
                content: (
                  <FaFire style={{ fontSize: "18px", marginRight: 8 }} />
                ),
                fontSize: "",
              },
              // { content: `Calories: `, fontSize: '20px' },
              { content: `${calories}`, fontSize: "26px" },
              { content: ` kcal`, fontSize: "16px" },
            ],
            top: "calc(5% + 240px)",
            right: rightPosition3,
            fontSize: "20px",
          });
          break;
        default:
          break;
      }
    }

    setDataText(texts);
  };

  const getBoxDimensions = () => {
    const widthSize = 500;
    if (aspectRatio === 1) {
      return { width: widthSize, height: widthSize }; // 1:1 비율
    } else if (aspectRatio === 9 / 16) {
      return { width: widthSize, height: (widthSize / 9) * 16 }; // 9:16 비율
    } else {
      return { width: widthSize, height: widthSize }; // 기본값 (1:1)
    }
  };

  const { width, height } = getBoxDimensions();

  useEffect(() => {
    if (runData) {
      setData(runData);
      setRunChartData(runChartData);
      createTextLayout();
    }
  }, [runData, textOption]);

  return (
    <Container>
      <Typography variant="h4" align="center" gutterBottom>
        <FaRunning />
      </Typography>

      <AspectRatioPicker
        aspectRatio={aspectRatio}
        handleAspectRatioChange={handleAspectRatioChange}
      />

      <input type="file" accept="image/*" onChange={onChange} />

      <BackgroundColorPicker
        backgroundColorSet={backgroundColorSet}
        backgroundColor={backgroundColor}
        handleBackgroundColorChange={handleBackgroundColorChange}
      />

      <TextColorPicker
        fontColorSet={fontColorSet}
        textColor={textColor}
        handleTextColorChange={handleTextColorChange}
      />

      <FontPicker
        fontFamily={fontFamily}
        handleFontFamilyChange={handleFontFamilyChange}
      />

      <TextLayoutPicker
        textOptions={textOptions}
        textOption={textOption}
        handleTextOptionChange={handleTextOptionChange}
      />

      <FormControl component="fieldset" sx={{ mt: 2, width: "100%" }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={layoutEnabled}
              onChange={(e) => setLayoutEnabled(e.target.checked)}
            />
          }
          label="Enable Layout"
          sx={{ mt: 1 }}
        />

        <FormControlLabel
          control={
            <Checkbox
              checked={backgroundEnabled}
              onChange={(e) => setBackgroundEnabled(e.target.checked)}
            />
          }
          label="Enable Text Background"
          sx={{ mt: 1 }}
        />
      </FormControl>

      <FormControl component="fieldset" sx={{ mt: 2, width: "100%" }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={chartLayoutEnabled}
              onChange={(e) => setChartLayoutEnabled(e.target.checked)}
            />
          }
          label="Enable Chart Layout"
          sx={{ mt: 1 }}
        />

        <Box>
          <Typography variant="h6">Chart Type</Typography>
          <Box>
            {chartType.map((option) => (
              <ToggleButton
                key={option.value}
                value={option.value}
                selected={showChartType === option.value}
                onClick={() => handleShowChartTypeChange(option.value)}
                sx={{ mr: 1 }}
              >
                {option.label}
              </ToggleButton>
            ))}
          </Box>
        </Box>

        {showChartType === 'time' && (
          <TimePicker
            predefinedTimes={predefinedTimes}
            timeInterval={timeInterval}
            handleIntervalTimeChange={handleIntervalTimeChange}
          />
        )}
        {showChartType === 'distance' && (
          <DistancePicker
            predefinedDistances={predefinedDistances}
            distanceInterval={distanceInterval}
            handleIntervalDistanceChange={handleIntervalDistanceChange}
          />
        )}

        <FormControlLabel
          control={
            <Checkbox
              checked={chartColorEnabled}
              onChange={(e) => setChartColorEnabled(e.target.checked)}
            />
          }
          label="Change Chart Color Set"
          sx={{ mt: 1 }}
        />

        {chartColorEnabled && (
          <ChartColorPicker
            chartColorSet={chartColorSet}
            handleChartColorSetChange={handleChartColorSetChange}
            useColorIndex={[0, 2, 4]}
          />
        )}

        <FormControlLabel
          control={
            <Checkbox
              checked={heartrateEnabled}
              onChange={(e) => setHeartrateEnabled(e.target.checked)}
            />
          }
          label="Show HR Line"
          sx={{ mt: 1 }}
        />

        <FormControlLabel
          control={
            <Checkbox
              checked={cadenceEnabled}
              onChange={(e) => setCadenceEnabled(e.target.checked)}
            />
          }
          label="Show Cadence Line"
          sx={{ mt: 1 }}
        />
      </FormControl>

      <CropImageModal
        image={image}
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        cropperRef={cropperRef}
        handleCrop={handleCrop}
        aspectRatio={aspectRatio}
      />

      {dataText && (
        <RunImagePreview
          imageRef={imageRef}
          width={width}
          height={height}
          cropData={cropData}
          RunChartComponent={RunChart}
        />
      )}

      <Button
        variant="contained"
        color="secondary"
        onClick={downloadImage}
        sx={{ mt: 2 }}
      >
        📸
      </Button>
    </Container>
  );
};

export default RunDetail;
