import axios from 'axios';

// const API_URL = 'http://localhost:8080/api'; // API 엔드포인트 설정
const API_URL = 'https://penne.wbco.site/api'; // API 엔드포인트 설정

// // JWT 토큰을 로컬 스토리지에 저장
// export const saveToken = (token) => {
//   if (token) {  // token 값이 존재하는 경우에만 저장
//     console.log('Saving token:', token);
//     localStorage.setItem('token', token);
//   } else {
//     console.error('No token provided, skipping save.');
//   }
// };

// 로컬 스토리지에서 JWT 토큰 가져오기
export const getToken = () => {
  return localStorage.getItem('token');
};

//  sign up
export const signup = async (username, email, password) => {
  try {
    const response = await axios.post(`${API_URL}/auth/signup`, { username, email, password });
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : new Error('Signup failed');
  }
};

// login
export const signin = async (username, password) => {
  try {
    const response = await axios.post(`${API_URL}/auth/signin`, { username, password });
    // response.data.response.accessToken이 존재하는지 확인
    // const token = response.data.response?.accessToken;
    return response.data;
  } catch (error) {
    console.error('Signin error:', error);
    throw error.response ? error.response.data : new Error('Signin failed');
  }
};

// 사용자 정보 가져오기 (프로필)
export const fetchUserProfile = async () => {
  try {
    const token = getToken();
    // console.log(token);
    const response = await axios.get(`${API_URL}/auth/profile`, {
      headers: {
        Authorization: `Bearer ${token}`, // JWT 토큰을 헤더에 포함하여 요청
      },
    });
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : new Error('Failed to fetch profile');
  }
};

// fetch strava connet url
export const fetchStravaConnectUrl = async () => {
  try {
    const token = getToken();
    const response = await axios.get(`${API_URL}/strava/connectUrl`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : new Error('Failed to get Strava connect URL');
  }
};

export const fetchActivities = async (startDate, endDate) => {
  console.log(startDate, endDate);
  try {
    const token = getToken();
    const response = await axios.get(`${API_URL}/strava/activity/list`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        userTimezoneOffsetInHours: 9,
        startDate: startDate.format('YYYY-MM-DDT00:00:00'),
        endDate: endDate.format('YYYY-MM-DDT23:59:59'),
      },
    });
    console.log(response.data);
    return response.data;
    // return activitiesJson;
  } catch (error) {
    throw error.response ? error.response.data : new Error('Failed to fetch activities');
  }
};

export const fetchActivity = async (activityId) => {
  // console.log(id);
  try {
    const token = getToken();
    const response = await axios.get(`${API_URL}/strava/activity/${activityId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data.response;
    // return activitiesJson;
  } catch (error) {
    throw error.response ? error.response.data : new Error('Failed to fetch activities');
  }
};

export const fetchActivityStream = async (activityId) => {
  // console.log(id);
  try {
    const token = getToken();
    const response = await axios.get(`${API_URL}/strava/activity/stream/${activityId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    // console.log(response.data.response);
    return response.data.response;
    // return activitiesJson;
  } catch (error) {
    throw error.response ? error.response.data : new Error('Failed to fetch activities');
  }
};

export const fetchStatMonthActivities = async (yymm, sportType) => {
  try {
    const token = getToken();
    const response = await axios.get(`${API_URL}/strava/activity/month/${yymm}/${sportType}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        userTimezoneOffsetInHours: 9,
      },
    });
    console.log(response.data);
    return response.data;
    // return activitiesJson;
  } catch (error) {
    throw error.response ? error.response.data : new Error('Failed to fetch activities');
  }
};

