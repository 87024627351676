import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Container, Typography, CircularProgress, List, ListItem, ListItemText, Box, TextField, Button } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { fetchActivities } from '../services/ApiService';
import { PolylineToSVG } from '../components/Polyline';
import dayjs from 'dayjs';

import activitiesJson from '../services/activities.json';

function Activities() {
  const [activities, setActivities] = useState([]);
  const [loading, setLoading] = useState(true); // 초기 로딩 상태를 true로 설정
  const [error, setError] = useState('');
  const [startDate, setStartDate] = useState(dayjs().startOf('week'));
  const [endDate, setEndDate] = useState(dayjs().endOf('week'));

  useEffect(() => {
    loadActivities(); // 페이지가 처음 로드될 때 데이터를 불러옴
  }, []);

  const loadActivities = async () => {
    setLoading(true);
    try {
      // const data = await fetchActivities(startDate, endDate);
      // setActivities(data.response);
      setActivities(activitiesJson.response);
    } catch (err) {
      setError(err.message || 'Failed to fetch activities');
    } finally {
      setLoading(false);
    }
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const disableEndDate = (date) => {
    return date.isBefore(startDate) || date.diff(startDate, 'month') > 1;
  };

  const disableStartDate = (date) => {
    return date.isAfter(endDate) || endDate.diff(date, 'month') > 1;
  };

  if (loading) return <CircularProgress />;
  if (error) return <Typography color="error">{error}</Typography>;

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Container maxWidth="md">
        <Typography variant="h4" gutterBottom>
          Strava Activities1
        </Typography>
        <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
          <DatePicker
            label="Start Date"
            value={startDate}
            onChange={handleStartDateChange}
            shouldDisableDate={disableStartDate}
            renderInput={(params) => <TextField {...params} />}
          />
          <DatePicker
            label="End Date"
            value={endDate}
            onChange={handleEndDateChange}
            shouldDisableDate={disableEndDate}
            renderInput={(params) => <TextField {...params} />}
          />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
          <Button variant="contained" color="primary" onClick={loadActivities}>
            Load Activities
          </Button>
        </Box>
        <List>
          {console.log(activities)}
          {activities?.map((activity) => (
            <ListItem key={activity.id} alignItems="flex-start">
              <ListItemText
                primary={
                  <Box 
                    component={Link} 
                    to={`/activity/${activity.id}`} 
                    display="flex" 
                    alignItems="center" 
                    style={{ textDecoration: 'none' }} // 링크 밑줄 제거
                  >
                    <Typography component="span" variant="h6" color="textPrimary" style={{ marginRight: '8px' }}>{activity.name}</Typography>
                    <Typography component="span" variant="body2" color="textSecondary">{activity.id}</Typography>
                  </Box>
                }
                secondary={
                  <Box>
                    {
                      activity.map?.summaryPolyline && 
                      <Box>
                        <PolylineToSVG
                          width={60}
                          height={60}
                          polyline={activity.map.summaryPolyline}
                          style={{}}
                        />
                      </Box>
                    }
                    <Typography component="span" variant="body2" color="textPrimary">
                      Type: {activity.sportType}, Distance: {(activity.distance / 1000).toFixed(2)} km
                    </Typography>
                    <br />
                    <Typography component="span" variant="body2" color="textPrimary">
                      Date: {new Date(activity.startDateLocal).toLocaleString()}, Location: {activity.locationCountry}
                    </Typography>
                    <br />
                    <Typography component="span" variant="body2" color="textPrimary">
                      Average Speed: {`${((activity.averageSpeed) * 3.6).toFixed(2)} km/h`}, Max Speed: {`${((activity.maxSpeed) * 3.6).toFixed(2)} km/h`}
                    </Typography>
                    <br />
                    <Typography component="span" variant="body2" color="textPrimary">
                      Average Heartrate: {activity.averageHeartrate}, MaxHeartrate: {activity.maxHeartrate}
                    </Typography>
                  </Box>
                }
              />
            </ListItem>
          ))}
        </List>
      </Container>
    </LocalizationProvider>
  );
}

export default Activities;
