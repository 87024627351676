import React, { useState } from 'react';
import { FormControl, Typography, Box, ToggleButton } from '@mui/material';

// 각 브랜드별 색상 세트
export const colorSetByDefault = ['#54acd2', '#61bd6d', '#f7da64', '#fba026', '#e25041', '#9365b8'];
const colorSet = {
  Car : ['#007BA7', '#2E8B57', '#FFD700', '#FFA500', '#FF6347', '#800080'], // Cartier: Deep Sky Blue, Sea Green, Gold, Orange, Tomato, Purple
  Her : ['#3F00FF', '#006400', '#FFFF00', '#FF4500', '#FF0000', '#4B0082'], // Hermès: Electric Ultramarine, Indigo, Yellow, Orange Red, Red, Dark Green
  Pra : ['#0F52BA', '#008000', '#FFD700', '#FF8C00', '#DC143C', '#9400D3'], // Prada: Sapphire, Green, Gold, Dark Orange, Crimson, Dark Violet
  Guc : ['#1034A6', '#228B22', '#FFD700', '#FFA500', '#FF4500', '#800080'], // Gucci: Egyptian Blue, Forest Green, Gold, Orange, Orange Red, Purple
  Lou : ['#0000FF', '#008000', '#FFFF00', '#FFA500', '#FF0000', '#800080'], // Louis Vuitton: Blue, Green, Yellow, Orange, Red, Purple
  Cha : ['#27408B', '#2E8B57', '#FFD700', '#FF7F50', '#FF0000', '#8B008B'], // Chanel: Royal Blue, Sea Green, Gold, Coral, Red, Dark Magenta
  Bur : ['#1E90FF', '#006400', '#FFD700', '#FF8C00', '#FF6347', '#9932CC'], // Burberry: Dodger Blue, Dark Green, Gold, Dark Orange, Tomato, Dark Orchid
  Rol : ['#4682B4', '#2E8B57', '#FFD700', '#FFA500', '#B22222', '#800080'], // Rolex: Steel Blue, Sea Green, Gold, Orange, Firebrick, Purple
  Val : ['#1E90FF', '#32CD32', '#FFD700', '#FF4500', '#FF0000', '#9370DB'], // Valentino: Dodger Blue, Lime Green, Gold, Orange Red, Red, Medium Purple
  Dol : ['#1E90FF', '#009B77', '#FADA5E', '#FF8243', '#E32636', '#B57EDC'], // Dolce & Gabbana: Dodger Blue, Persian Green, Lemon Yellow, Mango Tango, Alizarin Crimson, Lavender
  Ver : ['#00BFFF', '#008000', '#FFFF00', '#FF6347', '#FF0000', '#8A2BE2'], // Versace: Deep Sky Blue, Green, Yellow, Tomato, Red, Blue Violet
  Ysl : ['#000080', '#228B22', '#FFD700', '#FF4500', '#FF0000', '#8B008B'], // Yves Saint Laurent: Navy, Forest Green, Gold, O  Bal : ['#000080', '#2E8B57', '#FFD700', '#FF4500', '#FF0000', '#800080'], // Balenciaga: Navy, Sea Green, Gold, Orange Red, Red, Purple
  Giv : ['#0F4D92', '#32CD32', '#FFD700', '#FFA500', '#FF6347', '#BA55D3'], // Givenchy: Yale Blue, Lime Green, Gold, Orange, Tomato, Medium Orchid
  Fen : ['#1E90FF', '#00FA9A', '#FFD700', '#FF1493', '#FF4500', '#9932CC'], // Fendi: Deep Pink, Medium Spring Green, Gold, Dodger Blue, Orange Red, Dark Orchid
  Tom : ['#483D8B', '#2E8B57', '#FFD700', '#FFA500', '#DC143C', '#8B008B'], // Tom Ford: Dark Slate Blue, Sea Green, Gold, Orange, Crimson, Dark Magenta
};

// 메인 컴포넌트
const ChartColorPicker = ({ handleChartColorSetChange, useColorIndex = [0, 1, 2, 3, 4, 5] }) => {

  // 현재 선택된 색상 세트를 상태로 관리
  const [selectedSet, setSelectedSet] = useState(colorSetByDefault);

  const handleSetChange = (newSet) => {
    setSelectedSet(newSet); // 선택한 색상 세트를 로컬 상태로 저장
    handleChartColorSetChange(newSet); // 부모 컴포넌트로 선택한 색상 세트 전달
  };

  return (
    <FormControl component="fieldset" sx={{ mt: 2 }}>
      <Typography variant="h6">Color Sets</Typography>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        {/* 버튼을 눌러서 각 색상 세트를 선택할 수 있는 버튼 그룹 */}
        <Box>
          <ToggleButton
            value="default"
            selected={selectedSet === colorSetByDefault}
            onClick={() => handleSetChange(colorSetByDefault)}
          >
            Default
          </ToggleButton>
          {Object.entries(colorSet).map(([brand, set]) => (
            <ToggleButton
              key={brand}
              value={brand.toLowerCase()}
              selected={selectedSet === set}
              onClick={() => handleSetChange(set, brand)}
            >
              {brand}
            </ToggleButton>
          ))}
        </Box>

        {/* 선택된 색상 세트에 해당하는 색상들이 표시되는 영역 */}
        <Box sx={{ display: 'flex', gap: 1 }}>
          {selectedSet.map((color, index) => (
            useColorIndex.includes(index) && (
              <Box
                key={index} // 중복 가능성을 피하기 위해 index를 key로 사용
                sx={{
                  width: 40,
                  height: 40,
                  backgroundColor: color,
                  border: `2px solid ${color === '#ffffff' ? '#000' : 'transparent'}`,
                }}
              />
            )
        ))}
        </Box>
      </Box>
    </FormControl>
  );
};

export default ChartColorPicker;
