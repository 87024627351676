import React from 'react';
import { AppBar, Toolbar, Typography, Container, Box, Button } from '@mui/material';

const MainLayout = ({ children }) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
            My MUI App
          </Typography>
          <Button color="inherit" href="/signup">
            Sign Up
          </Button>
          <Button color="inherit" href="/signin">
            Signin
          </Button>
          <Button color="inherit" href="/profile">
            Profile
          </Button>
          <Button color="inherit" href="/activities">
            Activities
          </Button>
        </Toolbar>
      </AppBar>
      <Container component="main" sx={{ flexGrow: 1, mt: 2 }} style={{maxWidth: 800}}>
        {children}
      </Container>
      <Box component="footer" sx={{ p: 2, backgroundColor: '#f7f7f7', textAlign: 'center' }}>
        <Typography variant="body1">© 2024 My MUI App</Typography>
      </Box>
    </Box>
  );
}

export default MainLayout;
